<template>
  <b-modal
    title="Seleccionar sectores"
    ref="sectors"
    id="sectors"
    v-model="isShow"
    size="20"
    no-close-on-backdrop
    scrollable
    hide-header-close
    class="w-50"
  >
    <b-row class="mb-3">
      <b-col>
        <button class="btn btn-primary btn-sm" @click="selectAll()">
          <i class="la la-edit"></i> Seleccionar todos
        </button>
        <button
          class="btn btn-link btn-sm ml-3"
          @click="
            () => {
              cors = [];
              checkedSectors = [];
            }
          "
        >
          <i class="la la-ban"></i> Limpiar
        </button>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col md="12" class="mb-2">
        <span class="form-text text-muted"
          >Para continuar seleccione al menos un COR y al menos sector</span
        >
      </b-col>
      <b-col
        md="6"
        class="mt-3"
        v-for="(item, index) of dataSectors"
        :key="index"
      >
        <div class="checkbox-list">
          <label class="checkbox">
            <input
              v-model="cors"
              type="checkbox"
              :id="item.nombre"
              :value="item.nombre"
              readonly
              disabled
            />
            <span></span>
            {{ item.nombre }}
          </label>
        </div>
        <div class="separator separator-dashed text-dark mb-3 mt-3"></div>
        <div class="row">
          <div
            class="col-md-12 d-flex align-items-center"
            v-for="(i, idx) of item.sectores"
            :key="idx"
          >
            <div class="checkbox-list">
              <label class="checkbox">
                <input
                  type="checkbox"
                  :id="i.sector"
                  :value="i.sector"
                  v-model="checkedSectors"
                />
                <span></span>
                {{ i.sector }}
              </label>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <template #modal-footer>
      <div class="form-group">
        <button
          v-if="checkedSectors.length != 0 && cors.length != 0"
          @click="confirmar"
          class="btn btn-success btn-sm"
        >
          <i class="la la-sync"></i> Confirmar
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
export default {
  name: "select-sectors",
  emits: ["loadTree"],
  data() {
    return {
      cors: ["ATLANTICO", "NORTE"],
      isShow: false,
      dataSectors: [],
      checkedSectors: [],
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    corLength() {
      return this.cors;
    },
    sectorOfCor: {
      get() {
        let filt = this.dataSectors.filter((f) => this.cors.includes(f.nombre));
        var sct = [];
        filt.map((el) => {
          sct = _.concat(sct, el.sectores);
        });
        sct = _.uniqBy(sct, "sector");
        return sct;
      },
      set(val) {
        this.sectorOfCor = val;
      },
    },
  },
  methods: {
    ...mapActions({
      setSectors: "auth/setSectors",
    }),
    defaultInfo() {
      if (sessionStorage.getItem("sectores") != null) {
        if (JSON.parse(sessionStorage.sectores).length == 0) {
          let x = [];
          let c = this.dataSectors.filter((f) => this.cors.includes(f.nombre));

          c.map((l) => {
            l.sectores.map((s) => {
              x.push(s.sector);
            });
          });
          this.checkedSectors = _.uniq(x);
        } else {
          this.checkedSectors = JSON.parse(sessionStorage.sectores);
        }
      }
    },
    loadSector() {
      axios
        .get("/cors")
        .then((res) => {
          this.dataSectors = res.data;
          sessionStorage.setItem(
            "sectores",
            JSON.stringify(this.checkedSectors)
          );
          if (this.user.roles.name == "Contratista") {
            this.checkedSectors = [this.user.sector];
            this.confirmar();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    selectAll() {
      if (this.cors.length == this.dataSectors.length) {
        this.cors = [];
      } else {
        let checked = [];
        this.cors = this.dataSectors.map((c) => {
          c.sectores.map((s) => {
            checked.push(s.sector);
          });
          return c.nombre;
        });
        checked = _.uniq(checked);
        this.checkedSectors = checked;
      }
    },
    confirmar() {
      this.isShow = false;
      this.cors = this.cors.map((c) => {
        return `'${c}'`;
      });
      this.cors = _.uniq(this.cors);
      this.checkedSectors = _.uniq(this.checkedSectors);
      sessionStorage.setItem("sectores", JSON.stringify(this.checkedSectors));
      this.setSectors(this.checkedSectors);
      sessionStorage.setItem("cor", _.join(this.cors, ","));
      this.$parent.dataCor = _.join(this.cors, ",");
      this.$emit("loadTree", {
        load: true,
        cor: _.join(this.cors, ","),
      });
    },
  },
};
</script>
