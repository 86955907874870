<template>
  <b-modal
    title="Buscar trabajo"
    ref="workConsult"
    id="workConsult"
    v-model="show"
    size="xl"
    no-close-on-backdrop
    scrollable
  >
    <div class="mt-3" v-show="items.length != 0">
      <b-card no-body>
        <b-tabs card small>
          <b-tab title="Básico" active>
            <b-row class="mt-3">
              <b-col md="12">
                <gmap-map
                  :center="center"
                  :zoom="zoom"
                  style="width: 100%; height: 300px"
                  ref="map"
                  v-show="items.length != 0"
                >
                  <gmap-info-window
                    :options="infoOptions"
                    :position="infoWindowPos"
                    :opened="infoWinOpen"
                    @closeclick="infoWinOpen = false"
                  >
                  </gmap-info-window>
                  <gmap-marker
                    :key="i"
                    v-for="(m, i) in markers"
                    :position="m.position"
                    :icon="m.icon"
                    :clickable="true"
                    :animation="2"
                    @click="toggleInfoWindow(m, i)"
                  >
                  </gmap-marker>
                </gmap-map>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12" class="mt-3">
                <DxDataGrid
                  :allow-column-resizing="true"
                  :data-source="ot"
                  :show-borders="true"
                  :show-column-lines="true"
                  :show-row-lines="true"
                  :row-alternation-enabled="true"
                  :columnAutoWidth="true"
                  v-if="ot.length != 0"
                >
                  <DxPaging :page-size="10" />
                  <DxHeaderFilter :visible="true" />
                  <DxPager
                    :visible="false"
                    infoText="Página {0} of {1} ({2} registros)"
                    :allowed-page-sizes="pageSizes"
                    display-mode="full"
                    :show-page-size-selector="true"
                    :show-info="true"
                    :show-navigation-buttons="true"
                  />
                  <DxColumn data-field="num_ot" caption="N° OT" />
                  <DxColumn
                    data-field="estado"
                    caption="Estado"
                    cell-template="cellTemplate"
                  />

                  <DxColumn data-field="nombre_brigada" caption="Brigada" />
                  <DxColumn
                    data-field="fecha_asigna"
                    caption="Fecha"
                    data-type="date"
                    format="dd/MM/yyyy, HH:mm:ss"
                  />
                  <template #cellTemplate="{ data: cellData }">
                    <div>
                      <i :class="cellData.data.icono"></i>
                      {{ cellData.data.estado }}
                    </div>
                  </template>
                </DxDataGrid>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <DxDataGrid
                  :allow-column-resizing="true"
                  :data-source="items"
                  :show-borders="true"
                  :show-column-lines="true"
                  :show-row-lines="true"
                  :row-alternation-enabled="true"
                  :columnAutoWidth="true"
                  v-if="items.length != 0"
                >
                  <DxPaging :page-size="10" />
                  <DxHeaderFilter :visible="true" />
                  <DxPager
                    :visible="false"
                    infoText="Página {0} of {1} ({2} registros)"
                    :allowed-page-sizes="pageSizes"
                    display-mode="full"
                    :show-page-size-selector="true"
                    :show-info="true"
                    :show-navigation-buttons="true"
                  />
                  <DxColumn
                    caption=""
                    type="buttons"
                    cell-template="cellTemplate"
                  />
                  <DxColumn
                    data-field="num_aviso"
                    caption="N° Aviso"
                    alignment="center"
                  />
                  <DxColumn caption="Brigada" cell-template="templateBrigada" />
                  <DxColumn
                    data-field="fecha_alta"
                    caption="Fecha de ingreso"
                    data-type="date"
                    format="dd/MM/yyyy, HH:mm:ss"
                  />
                  <DxColumn data-field="departamento" caption="Departamento" />
                  <DxColumn data-field="municipio" caption="Municipio" />
                  <DxColumn data-field="circuito_sm" caption="Circuito" />
                  <DxColumn data-field="barrio" caption="Barrio" />
                  <DxColumn data-field="dir_suscriptor" caption="Dirección" />
                  <DxColumn data-field="nombre_suscriptor" caption="Cliente" />
                  <template #cellTemplate="{ data: cellData }">
                    <div>
                      <img
                        width="27"
                        :src="`${URL_ICON}${cellData.data.icono}.png`"
                        :alt="
                          cellData.data.icon
                            ? cellData.data.icon.descripcion
                            : 'Icono'
                        "
                      />
                    </div>
                  </template>
                  <template #templateBrigada>
                    <div>
                      {{ ot.length != 0 ? ot[0].nombre_brigada : "" }}
                    </div>
                  </template>
                </DxDataGrid>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Aviso" v-if="items.length == 1">
            <b-row>
              <b-col md="12">
                <TableWarning :aviso="items2" />
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Bitacora" v-if="showBitacora">
            <TableBinnacle :binnacle="binnacle"/>
          </b-tab>
          <b-tab lazy title="Información de precierre" v-if="movil">
            <b-row class="mb-3" v-if="movil.accion.length">
              <b-col>
                <b>ACCIONES</b>
                <DxDataGrid
                  :data-source="movil.accion"
                  :show-borders="true"
                  :show-column-lines="true"
                  :show-row-lines="true"
                  :row-alternation-enabled="true"
                  :columnAutoWidth="true"
                  :allow-column-resizing="true"
                >
                  <DxSearchPanel
                    :visible="false"
                    :width="240"
                    placeholder="Buscar..."
                  />
                  <DxPaging :page-size="10" />
                  <DxHeaderFilter :visible="true" />
                  <DxFilterRow :visible="true" />
                  <DxPager
                    :visible="false"
                    infoText="Página {0} of {1} ({2} registros)"
                    :allowed-page-sizes="pageSizes"
                    display-mode="full"
                    :show-page-size-selector="true"
                    :show-info="true"
                    :show-navigation-buttons="true"
                  />
                  <DxColumn data-field="descripcion" caption="Descripción" />
                  <DxColumn
                    data-field="fecha_ejecuta"
                    caption="Fecha"
                    data-type="date"
                    format="dd/MM/yyyy, HH:mm:ss"
                  />
                </DxDataGrid>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="mb-3">
                <b>CAUSAS</b>
                <DxDataGrid
                  :data-source="movil.causa"
                  :show-borders="true"
                  :show-column-lines="true"
                  :show-row-lines="true"
                  :row-alternation-enabled="true"
                  :columnAutoWidth="true"
                  :allow-column-resizing="true"
                  v-if="movil.causa.length != 0"
                >
                  <DxSearchPanel
                    :visible="false"
                    :width="240"
                    placeholder="Buscar..."
                  />
                  <DxPaging :page-size="20" />
                  <DxHeaderFilter :visible="true" />
                  <DxFilterRow :visible="true" />
                  <DxPager
                    :visible="false"
                    infoText="Página {0} of {1} ({2} registros)"
                    :allowed-page-sizes="pageSizes"
                    display-mode="full"
                    :show-page-size-selector="true"
                    :show-info="true"
                    :show-navigation-buttons="true"
                  />
                  <DxColumn data-field="titulo" caption="Causa" />
                  <DxColumn data-field="detalle" caption="Descripción" />
                </DxDataGrid>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b>REGLAS DE ORO</b>
                <DxDataGrid
                  :data-source="movil.reglas_oro"
                  :show-borders="true"
                  :show-column-lines="true"
                  :show-row-lines="true"
                  :row-alternation-enabled="true"
                  :columnAutoWidth="true"
                  :allow-column-resizing="true"
                  v-if="movil.reglas_oro.length != 0"
                >
                  <DxSearchPanel
                    :visible="false"
                    :width="240"
                    placeholder="Buscar..."
                  />
                  <DxPaging :page-size="15" />
                  <DxHeaderFilter :visible="true" />
                  <DxFilterRow :visible="true" />
                  <DxPager
                    :visible="false"
                    infoText="Página {0} of {1} ({2} registros)"
                    :allowed-page-sizes="pageSizes"
                    display-mode="full"
                    :show-page-size-selector="true"
                    :show-info="true"
                    :show-navigation-buttons="true"
                  />
                  <DxColumn data-field="codigo" caption="Código" />
                  <DxColumn data-field="descripcion" caption="Descripción" />
                  <DxColumn
                    data-field="fechaEjecuta"
                    caption="Fecha ejecución"
                    data-type="date"
                    format="dd/MM/yyyy, HH:mm:ss"
                  />
                </DxDataGrid>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab
            lazy
            title="Materiales"
            v-if="
              retirados && instalados && (retirados.length || instalados.length)
            "
          > 
            <TablesMaterials :instalados="instalados" :retirados="retirados" />
          </b-tab>
          <b-tab title="Galeria" v-if="galeria.length != 0">
            <TableGallery :galeria="galeria" />
          </b-tab>
          <b-tab lazy title="Datos de certificación" v-if="certificate.length">
            <b-row>
              <b-col>
                <b>INFORMACIÓN DE CERTIFICACIÓN</b>
                <DxDataGrid
                  :data-source="certificate"
                  :show-borders="true"
                  :show-column-lines="true"
                  :show-row-lines="true"
                  :row-alternation-enabled="true"
                  :columnAutoWidth="true"
                  :allow-column-resizing="true"
                >
                  <DxPaging :page-size="30" />
                  <DxHeaderFilter :visible="true" />
                  <DxFilterRow :visible="true" />
                  <DxPager
                    :visible="true"
                    infoText="Página {0} of {1} ({2} registros)"
                    :allowed-page-sizes="pageSizes"
                    display-mode="full"
                    :show-page-size-selector="true"
                    :show-info="true"
                    :show-navigation-buttons="true"
                  />
                </DxDataGrid>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
    <template #modal-footer>
      <button class="btn btn-sm btn-link" @click="() => (show = false)">
        <i class="la la-reply"></i> Cerrar
      </button>
    </template>
  </b-modal>
</template>
<style>
.table-response {
  width: 100% !important;
  display: inline-table !important;
}
</style>
<script>
import axios from "axios";
import toastr from "toastr";
import moment from "moment";
import { mapGetters } from "vuex";
import {
  DxDataGrid,
  DxPaging,
  DxColumn,
  DxSearchPanel,
  DxFilterRow,
  DxHeaderFilter,
  DxPager,
} from "devextreme-vue/data-grid";
import _ from "lodash";
import ENV from "@/environment";
import TableWarning from "../tables/TableWarning.vue";
import TableBinnacle from "../tables/TableBinnacle.vue";
import TableGallery from "../tables/TableGallery.vue";
import TablesMaterials from "../tables/TablesMaterials.vue";
export default {
  components: {
    DxDataGrid,
    DxPaging,
    DxColumn,
    DxSearchPanel,
    DxFilterRow,
    DxHeaderFilter,
    DxPager,
    TableWarning,
    TableBinnacle,
    TableGallery,
    TablesMaterials
},
  name: "search-ot",
  data() {
    return {
      servidor:
        process.env.NODE_ENV == "development" ? ENV.SERVER_DEV : ENV.SERVER,
      // "http://138.197.75.131:9000",
      URL_ICON: ENV.ICONS,
      pageSizes: [5, 10, 15, 30],
      show: false,
      showBitacora: true,
      isLoading: false,

      criterion: 1,
      busqueda: null,
      otsAll: [],
      ot: [],
      items: [],
      items2: [],

      markers: [],
      zoom: 12,
      center: {
        lat: 10.9784174,
        lng: -74.8144132,
      },
      infoOptions: {
        content: "",
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      binnacle: null,
      movil: {
        causa: [],
        reglas_oro: [],
        accion: [],
      },
      galeria: [],
      materiales: [],
      retirados: [],
      instalados: [],
      certificate: [],
    };
  },
  filters: {
    replace_(values) {
      return values.replace("_", " ");
    },
    upperCaseWithReplace(value) {
      return value.replace(/_/g, " ").toUpperCase();
    },
    upperCase(values) {
      return values.toUpperCase();
    },
    format(values) {
      return moment(values).format("LLL");
    },
    simpleFormat(value) {
      return moment(value).format("l") + " " + moment(value).format("LTS");
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  methods: {
    reset() {
      this.isLoading = false;
      this.criterion = 1;
      this.busqueda = null;
      this.items = [];
      this.ot = [];
      this.certificate = [];
    },
    loadAllOT() {
      axios.get("ots").then((res) => (this.otsAll = res.data));
    },
    showCenterMap: function (place) {
      if (place.lat != undefined && place.lng != undefined) {
        this.center = { lat: place.lat, lng: place.lng };
      }
    },
    updateCenter: function (place) {
      this.center = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
    },
    toggleInfoWindow: function (marker, idx) {
      this.markerSelected = [];
      this.markerSelected.push(marker);
      this.infoWindowPos = marker.position;
      this.infoOptions.content = marker.infoText;

      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      } else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },

    search(num_aviso) {
      this.showBitacora = true;

      this.isLoading = true;
      let config = {
        criterio: 1,
        busqueda: num_aviso,
      };
      this.ot = [];
      this.items = [];
      axios
        .post("buscar-trabajos", config)
        .then((res) => {
          let data = res.data;
          this.certificate = data.certificate_info;
          if (data.records.length == 0) {
            toastr.info(data.message);
          } else {
            if (data.message == "not asociated to OT") {
              this.items = data.records;
              this.markers = [];
              this.items.map((el) => {
                this.markers.push({
                  position: {
                    lat: parseFloat(el.latitud),
                    lng: parseFloat(el.longitud),
                  },
                  icon: `${this.URL_ICON}${el.icon}.png`,
                });
                el.icono = el.icon;
              });
              this.show = true;
            }
            if (data.message == "success") {
              if (data.records.avisos == undefined) {
                this.ot = data.records;
                this.items = data.records[0].avisos;
                this.markers = [];
                this.items.map((el) => {
                  this.markers.push({
                    position: {
                      lat: parseFloat(el.latitud),
                      lng: parseFloat(el.longitud),
                    },
                    icon: `${this.URL_ICON}${el.icono}.png`,
                  });
                });
              } else {
                this.ot = data.records;
                this.items = data.records.avisos;
                this.markers = [];
                this.items.map((el) => {
                  this.markers.push({
                    position: {
                      lat: parseFloat(el.latitud),
                      lng: parseFloat(el.longitud),
                    },
                    icon: `${this.URL_ICON}${el.icono}.png`,
                  });
                });
              }
            }
          }
          this.center =
            this.markers.length != 0 ? this.markers[0].position : this.center;
          this.zoom = 16;
          if (this.ot.length != 0) {
            this.binnacle = res.data.bitacora_ot;
          } else {
            if (res.data.bitacora_aviso) {
              if (res.data.bitacora_aviso.length != 0) {
                this.binnacle = res.data.bitacora_aviso;
              } else {
                this.showBitacora = false;
              }
            }
          }
          this.movil = res.data.movil;
          this.materiales = res.data.materiales;
          this.retirados = data.dato_materiales_retirados;
          this.instalados = data.dato_materiales;
          this.galeria = res.data.anexos.filter((f) => f.path != "");

          let ref = [],
            cause = [
              "principal_id",
              "causa_id",
              "pregunta_id",
              "tipo_os",
              "resultado",
              "contrata",
              "tipo_cierre",
              "obj",
              "trafo_parcial",
              "created_at",
              "updated_at",
              "deleted_at",
            ];
          if (this.movil) {
            let el = this.movil;
            for (const key in el) {
              if (!cause.includes(key) && !_.isObject(el[key])) {
                ref.push({
                  titulo: key.replace("_", " ").toUpperCase(),
                  detalle: el[key],
                });
              }
              if (key == "pregunta_id") {
                ref.push({
                  titulo: "¿HUBO DAÑO?",
                  detalle: el[key] == 1 ? "NO" : "SI",
                });
              }
              if (key === "trafo_parcial") {
                ref.push({
                  titulo: "HAY " + key.replace("_", " ").toUpperCase(),
                  detalle: el[key] === 1 || el[key] === null ? "NO" : "SI",
                });
              }
            }
            this.movil.causa = ref;
          }
          let causeW = [
            "pivot",
            "id_aviso",
            "tipo_trabajo_id",
            "icon",
            "icono",
            "causa_improcedente",
            "updated_at",
          ];
          let warning = [];

          this.items.map((el) => {
            if (el.pivot.id_aviso_ref == el.id_aviso) {
              for (const key in el) {
                if (!causeW.includes(key) && !_.isObject(el[key])) {
                  warning.push({
                    titulo: key.replaceAll("_", " ").toUpperCase(),
                    detalle: el[key],
                  });
                }
                if (key == "causa_improcedente") {
                  if (el[key]) {
                    warning.push({
                      titulo: "CAUSA IMPROCEDENTE",
                      detalle: el[key],
                    });
                  }
                }
              }
            }
          });
          this.items2 = warning;
          let cert = [];
          let certCause = [
            "id",
            "ot_id",
            "anexo_cierre_codigo",
            "usuario_con_servicio",
            "causa_codigo",
            "danio",
            "accion",
            "causa_fallida",
            "causal",
            "familia",
            "tipo_incidencia",
            "tipo_instalacion",
            "cod_cierre",
            "animal_aereo",
            "sync",
            "message_response",
            "created_at",
            "updated_at",
            "tipo",
          ];
          this.certificate.map((el) => {
            for (const key in el) {
              if (!certCause.includes(key) && !_.isObject(el[key])) {
                cert.push({
                  titulo: key.replaceAll("_", " ").toUpperCase(),
                  detalle: el[key],
                });
              }
              if (key == "animal_name") {
                if (el[key] !== "" && el[key]) {
                  cert.push({
                    titulo: key.replaceAll("_", " ").toUpperCase(),
                    detalle: el[key],
                  });
                }
              }
              if (key === "usuario_con_servicio") {
                cert.push({
                  titulo: "USUARIO QUEDA CON SERVICIO?",
                  detalle: el[key] == 1 ? "SI" : "NO",
                });
              }
            }
          });
          this.certificate = cert;

          this.$emit("openTreeAction", { ot: this.ot, aviso: this.items });
          this.isLoading = false;
        })
        .catch((e) => {
          console.log(e);
          this.isLoading = false;
        });
    },
  },
};
</script>
