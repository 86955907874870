import { render, staticRenderFns } from "./quickUser.vue?vue&type=template&id=5cb57e03&"
import script from "./quickUser.vue?vue&type=script&lang=js&"
export * from "./quickUser.vue?vue&type=script&lang=js&"
import style0 from "./quickUser.vue?vue&type=style&index=0&id=5cb57e03&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports