import Vue from 'vue'
import Router from 'vue-router'

/**
 * *****************************************
 *  import the components of folder components
 * *****************************************
 */
import LoginComponent from './components/auth/Login'
import HomeComponent from './components/home/HomeComponent.vue'


import ProcessesReports from './components/processes/reports/ProcessesReports'
import ProcessesWorks from './components/processes/works/ProcessesWorks'

import managementOT from './components/processes/management/managementOT'
import individualOT from './components/processes/management/individualOT'

// Statistics
import GeoAnalisys from './components/geoanalysis/GeoAnalisysComponent'

// admin
import UserComponent from './components/admin/users/UserComponent.vue'

Vue.use(Router)
import store from './store/index'

import can from '../src/permission'

const router = new Router({
    routes: [
        { path: '*', redirect: '/home' },
        {
            path: '',
            name: '',
            component: HomeComponent
        },
        {
            path: '/login',
            name: 'login',
            component: LoginComponent,
            beforeEnter: (to, from, next) => {
                if (store.getters['auth/authenticated']) {
                    return next({ name: 'login' })
                } else {
                    next()
                }
            }
        },
        {
            path: '/home',
            name: 'home',
            component: HomeComponent,
            beforeEnter: (to, from, next) => {
                if (!store.getters['auth/authenticated']) {
                    return next({ name: 'login' })
                } else {
                    if (can(to.path)) {
                        next()
                    } else {
                        return next({ name: 'home' })
                    }
                }
            }
        },
        // COMPONENTE QUE MUESTRA EL MAPA
        {
            path: '/works',
            name: 'ProcessesWorks',
            component: ProcessesWorks,
            props: true,
            beforeEnter: (to, from, next) => {
                if (!store.getters['auth/authenticated']) {
                    return next({ name: 'login' })
                } else {
                    if (can(to.path)) {
                        next()
                    } else {
                        return next({ name: 'home' })
                    }
                }
            }
        },
        // MODULO DE REPORTES - EN PROCESO
        {
            path: '/reports',
            name: 'ProcessesReports',
            component: ProcessesReports,
            beforeEnter: (to, from, next) => {
                if (!store.getters['auth/authenticated']) {
                    return next({ name: 'login' })
                } else {
                    console.log(can(to.path));
                    if (can(to.path)) {
                        next()
                    } else {
                        return next({ name: 'home' })
                    }
                }
            }
        },
        // ADMINISTRADOR DE OT 
        {
            path: '/processes/management',
            name: 'managementOT',
            component: managementOT,
            beforeEnter: (to, from, next) => {
                if (!store.getters['auth/authenticated']) {
                    return next({ name: 'login' })
                } else {
                    if (can(to.path)) {
                        next()
                    } else {
                        return next({ name: 'home' })
                    }
                }
            }
        },
        // DETALLES DE OT - BOTON VER DETALLES
        {
            path: '/processes/management/show/:id',
            name: 'individualOT',
            component: individualOT,
            beforeEnter: (to, from, next) => {
                if (!store.getters['auth/authenticated']) {
                    return next({ name: 'login' })
                } else {
                    if (can('detalle-ot')) {
                        next()
                    } else {
                        return next({ name: 'home' })
                    }
                }
            }
        },
        {
            path: '/geoanalisys',
            name: 'geoanalisys',
            component: GeoAnalisys,
            beforeEnter: (to, from, next) => {
                if (!store.getters['auth/authenticated']) {
                    return next({ name: 'login' })
                } else {
                    next()
                }
            }
        },
        // USUARIOS
        {
            path: '/users',
            name: 'users',
            component: UserComponent,
            beforeEnter: (to, from, next) => {
                if (!store.getters['auth/authenticated']) {
                    return next({ name: 'login' })
                } else {
                    next()
                }
            }
        },
        {
            path: '/users/update/:id',
            name: 'usersUpdate',
            component: () => import('./components/admin/users/UpdateUser.vue'),
            beforeEnter: (to, from, next) => {
                if (!store.getters['auth/authenticated']) {
                    return next({ name: 'login' })
                } else {
                    next()
                }
            }
        },
        {
            path: '/permissions',
            name: 'permissions',
            component: () => import('./components/admin/permissions/PermissionComponent.vue'),
            beforeEnter: (to, from, next) => {
                if (!store.getters['auth/authenticated']) {
                    return next({ name: 'login' })
                } else {
                    if (can(to.path)) {
                        next()
                    } else {
                        return next({ name: 'home' })
                    }
                }
            }
        },
        {
            path: '/permissions/update/:id',
            name: 'permissionsUpdate',
            component: () => import('./components/admin/permissions/UpdatePermission.vue'),
            beforeEnter: (to, from, next) => {
                if (!store.getters['auth/authenticated']) {
                    return next({ name: 'login' })
                } else {
                    next()
                }
            }
        },
        {
            path: '/roles',
            name: 'roles',
            component: () => import('./components/admin/roles/RoleComponent.vue'),
            beforeEnter: (to, from, next) => {
                if (!store.getters['auth/authenticated']) {
                    return next({ name: 'login' })
                } else {
                    next()
                }
            }
        },
        {
            path: '/roles/update/:id',
            name: 'rolesUpdate',
            component: () => import('./components/admin/roles/UpdateRole.vue'),
            beforeEnter: (to, from, next) => {
                if (!store.getters['auth/authenticated']) {
                    return next({ name: 'login' })
                } else {
                    next()
                }
            }
        },
        {
            path: '/turns',
            name: 'turns',
            component: () => import('./components/admin/turns/TurnComponent.vue'),
            beforeEnter: (to, from, next) => {
                if (!store.getters['auth/authenticated']) {
                    return next({ name: 'login' })
                } else {
                    if (can(to.path)) {
                        next()
                    } else {
                        return next({ name: 'home' })
                    }
                }
            }
        },
        {
            path: '/turns/update/:id',
            name: 'turnUpdate',
            component: () => import('./components/admin/turns/UpdateTurn.vue'),
            beforeEnter: (to, from, next) => {
                if (!store.getters['auth/authenticated']) {
                    return next({ name: 'login' })
                } else {
                    if (can('update-turn')) {
                        next()
                    } else {
                        return next({ name: 'home' })
                    }
                }
            }
        },
        {
            path: '/turns/authorization/:id',
            name: 'authorization',
            component: () => import('./components/admin/turns/TurnAuthorization.vue'),
            beforeEnter: (to, from, next) => {
                if (!store.getters['auth/authenticated']) {
                    return next({ name: 'login' })
                } else {
                    if (can('update-turn')) {
                        next()
                    } else {
                        return next({ name: 'home' })
                    }
                }
            }
        },
        {
            path: '/vehicles',
            name: 'vehicles',
            component: () => import('./components/admin/vehicles/VehicleComponent.vue'),
            beforeEnter: (to, from, next) => {
                if (!store.getters['auth/authenticated']) {
                    return next({ name: 'login' })
                } else {
                    if (can(to.path)) {
                        next()
                    } else {
                        return next({ name: 'home' })
                    }
                }
            }
        },
        {
            path: '/vehicles/update/:id',
            name: 'updateVehicle',
            component: () => import('@/components/admin/vehicles/UpdateVehicle.vue'),
            beforeEnter: (to, from, next) => {
                if (!store.getters['auth/authenticated']) {
                    return next({ name: 'login' })
                } else {
                    if (can('assign-vehicle')) {
                        next()
                    } else {
                        return next({ name: 'home' })
                    }
                }
            }
        },
        {
            path: '/days',
            name: 'days',
            component: () => import('@/components/admin/day/DayComponent.vue'),
            beforeEnter: (to, from, next) => {
                if (!store.getters['auth/authenticated']) {
                    return next({ name: 'login' })
                } else {
                    if (can(to.path)) {
                        next()
                    } else {
                        return next({ name: 'home' })
                    }
                }
            }
        },
        {
            path: '/days/update/:id',
            name: 'updateDay',
            component: () => import('@/components/admin/day/UpdateDay.vue'),
            beforeEnter: (to, from, next) => {
                if (!store.getters['auth/authenticated']) {
                    return next({ name: 'login' })
                } else {
                    if (can(`edit-day`)) {
                        next()
                    } else {
                        return next({ name: 'home' })
                    }
                }
            }
        },
        {
            path: '/settlement',
            name: 'settlement',
            component: () => import('@/components/admin/settlement/SettlementComponent.vue'),
            beforeEnter: (to, from, next) => {
                if (!store.getters['auth/authenticated']) {
                    return next({ name: 'login' })
                } else {
                    if (can(to.path)) {
                        next()
                    } else {
                        return next({ name: 'home' })
                    }
                }
            }
        },
        {
            path: '/vehicle-type',
            name: 'vehicleType',
            component: () => import('@/components/admin/vehicle-type/VehicleTypeComponent.vue'),
            beforeEnter: (to, from, next) => {
                if (!store.getters['auth/authenticated']) {
                    return next({ name: 'login' })
                } else {
                    if (can(to.path)) {
                        next()
                    } else {
                        return next({ name: 'home' })
                    }
                }
            }
        },
        {
            path: '/vehicle-type/update/:id',
            name: 'updateVehicleType',
            component: () => import('@/components/admin/vehicle-type/UpdateVehicleType.vue'),
            beforeEnter: (to, from, next) => {
                if (!store.getters['auth/authenticated']) {
                    return next({ name: 'login' })
                } else {
                    if (can('update-vehicle-type')) {
                        next()
                    } else {
                        return next({ name: 'home' })
                    }
                }
            }
        },
        {
            path: '/marks',
            name: 'marks',
            component: () => import('@/components/admin/marks/MarkComponent.vue'),
            beforeEnter: (to, from, next) => {
                if (!store.getters['auth/authenticated']) {
                    return next({ name: 'login' })
                } else {
                    if (can(to.path)) {
                        next()
                    } else {
                        return next({ name: 'home' })
                    }
                }
            }
        },
        {
            path: '/marks/update/:id',
            name: 'updateMark',
            component: () => import('@/components/admin/marks/UpdateMark.vue'),
            beforeEnter: (to, from, next) => {
                if (!store.getters['auth/authenticated']) {
                    return next({ name: 'login' })
                } else {
                    if (can('update-mark')) {
                        next()
                    } else {
                        return next({ name: 'home' })
                    }
                }
            }
        },
        {
            path: '/admin-map',
            name: 'adminMap',
            component: () => import('@/components/admin/map/AdminMap.vue'),
            beforeEnter: (to, from, next) => {
                if (!store.getters['auth/authenticated']) {
                    return next({ name: 'login' })
                } else {
                    if (can(to.path)) {
                        next()
                    } else {
                        return next({ name: 'home' })
                    }
                }
            }
        },
        {
            path: '/actions',
            name: 'actions',
            component: () => import('@/components/admin/actions/ActionComponent.vue'),
            beforeEnter: (to, from, next) => {
                if (!store.getters['auth/authenticated']) {
                    return next({ name: 'login' })
                } else {
                    if (can(to.path)) {
                        next()
                    } else {
                        return next({ name: 'home' })
                    }
                }
            }
        },
        {
            path: '/actions/update/:id',
            name: 'actionUpdate',
            component: () => import('@/components/admin/actions/UpdateAction.vue'),
            beforeEnter: (to, from, next) => {
                if (!store.getters['auth/authenticated']) {
                    return next({ name: 'login' })
                } else {
                    if (can('update-actions')) {
                        next()
                    } else {
                        return next({ name: 'home' })
                    }
                }
            }
        },
        {
            path: '/notifications',
            name: 'Notification',
            component: () => import('@/components/admin/notifications/NotificationComponent.vue'),
            beforeEnter: (to, from, next) => {
                if (!store.getters['auth/authenticated']) {
                    return next({ name: 'login' })
                } else {
                    if (can('update-actions')) {
                        next()
                    } else {
                        return next({ name: 'home' })
                    }
                }
            }
        },
        {
            path: '/consulta-ws',
            name: 'consulta-ws',
            component: () => import('@/components/admin/ws/SearchWS.vue'),
            beforeEnter: (to, from, next) => {
                if (!store.getters['auth/authenticated']) {
                    return next({ name: 'login' })
                } else {
                    if (can(to.path)) {
                        next()
                    } else {
                        return next({ name: 'home' })
                    }
                }
            }
        },
        {
            path: '/brigadeTour',
            name: 'brigadeTour',
            component: () => import('@/components/admin/map/BrigadeTour.vue'),
            beforeEnter: (to, from, next) => {
                if (!store.getters['auth/authenticated']) {
                    return next({ name: 'login' })
                } else {
                    if (can(to.path)) {
                        next()
                    } else {
                        return next({ name: 'home' })
                    }
                }
            }
        },
        {
            path: '/reports-admin',
            name: 'reportAdmin',
            component: () => import('@/components/admin/reports/ReportComponent.vue'),
            beforeEnter: (to, from, next) => {
                if (!store.getters['auth/authenticated']) {
                    return next({ name: 'login' })
                } else {
                    if (can(to.path)) {
                        next()
                    } else {
                        return next({ name: 'home' })
                    }
                }
            }
        },
        {
            path: '/other-report',
            name: 'OtherReport',
            component: () => import('@/components/admin/reports/OtherReportComponent.vue'),
            children: [
                {
                    path: '/operative-schema',
                    name: 'OperativeSchema',
                    component: () => import('@/components/admin/reports/children/OperativeSchema.vue'),
                },
                {
                    path: '/certification',
                    name: 'CertificationReport',
                    component: () => import('@/components/admin/reports/children/CertificationReport.vue'),
                },
                {
                    path: '/report-time',
                    name: 'EjecutionTime',
                    component: () => import('@/components/admin/reports/utils/InformeEjecucionTab.vue'),
                },
                {
                    path: '/materials',
                    name: 'MaterialReport',
                    component: () => import('@/components/admin/reports/children/MaterialReport.vue'),
                },
                {
                    path: '/recorrido-reporte',
                    name: 'RecorridoReport',
                    component: () => import('@/components/admin/map/BrigadeTour.vue'),
                },
                {
                    path: '/administracion',
                    name: 'AdminOT',
                    component: () => import('@/components/processes/management/managementOT'),
                },
                {
                    path: '/liquidacion',
                    name: 'Liquidacion',
                    component: () => import('@/components/admin/settlement/SettlementComponent.vue'),
                }
            ],
            beforeEnter: (to, from, next) => {
                if (!store.getters['auth/authenticated']) {
                    return next({ name: 'login' })
                } else {
                    if (can(to.path)) {
                        next()
                    } else {
                        return next({ name: 'home' })
                    }
                }
            }
        }
    ]
});


export default router;