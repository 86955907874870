<template>
    <b-modal
        id="translate"
        ref="traslate-ot-modal"
        v-model="showTraslate"
        size="xl"
        title="Trasladar OT"
        no-close-on-backdrop
        hide-header-close
        >
        <h5>Información de OT</h5>
        <table class="table table-sm table-hover table-striped">
            <thead>
                <tr>
                    <td class="text-center">N° OT</td>
                    <td class="text-center">Observación</td>
                    <td class="text-center">Estado</td>
                    <td>Asignado a</td>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(info, index) in infoTraslate" :key="index + '|' ">
                    <td class="text-center"> {{ info.num_ot }} </td>
                    <td class="text-center"> {{ info.observacion }} </td>
                    <td class="text-center">  <i :class="info.estado_o_t_s.icono"></i> {{ info.estado_o_t_s.descripcion }} </td>
                    <td> {{ info.user.nombre }} </td>
                </tr>
            </tbody>
        </table>
        <b-row>
            <b-col md="4" class="mt-2 mb-3">
                <label>Seleccione brigada a la que desea trasladar la OT</label>
                <Select2 
                    v-if="this.techs.length != 1"
                    class="select2 mb-3"
                    v-model="worker"
                    :options="techs"
                /> 
                <select  
                    class="form-control form-control-sm" 
                    v-else>
                    <option :value="techs[0].id"> {{ techs[0].text }} </option>
                </select>
            </b-col>
        </b-row>
        <template #modal-footer>
            <b-form-group>
                <b-button 
                    variant="link" 
                    class="mr-2 text-mute" 
                    size="sm"
                    @click="hideModalTraslate()">
                    <i class="la la-undo"></i> Cancelar
                </b-button>
                <a 
                    class="btn btn-sm btn-outline-success"
                    size="sm"
                    ref="buttonLoader"
                    @click="updateOT()">
                    <i class="la la-exchange-alt"></i> Trasladar
                </a>
            </b-form-group>
        </template>
    </b-modal>
</template>
<script>
    import Select2 from 'v-select2-component'
    import toastr from 'toastr'
    import axios from 'axios'
    import * as $ from 'jquery'
    import { mapGetters } from 'vuex'
    import moment from 'moment'
    export default {
        name: 'ModalTraslate',
        components: { Select2 },
        props: {
            showTraslate: {
                type: Boolean,
                default: () => false
            },
            infoTraslate: {
                type: Array,
                default: () => []
            },
            techs: {
                type: Array,
                default: () => []
            },
            treeAssign: {
                type: Boolean,
                default: () => false
            }
        },
        data() {
            return {
                worker: -1,
                isLoading: false
            }
        },
        computed: {
            ...mapGetters({
                user: 'auth/user'
            })
        },
        methods: {
            removeTabIndex() {
                $('#translate___BV_modal_content_').attr('tabindex', null);
            },
            hideModalTraslate() {
                this.$emit('hideModalTraslate', false)
            },
            updateOT() {
                this.worker = this.treeAssign ? this.techs[0].id : this.worker;
                if(this.worker == -1 ) {
                    toastr.info("Para trasladar la OT es necesario seleccionar una brigada!")
                } else {
                    let data = {
                        id_ot: this.infoTraslate[0].id_ot,
                        brigada: this.worker,
                        id_estado: 2,
                        num_ot: this.infoTraslate[0].num_ot,
                        traslado: true,
                        bitacora: true,
                        descripcion: `NOVEDAD: EL OPERADOR [${this.user.usuario}]  TRASLADA LA ORDEN DE TRABAJO A:  ${this.worker}`,
                        fecha_bitacora: moment().format('YYYY-MM-DD h:mm:ss')
                    }
                    this.loaderSpinClasss()
                    axios.put('ots/' + this.infoTraslate[0].id_ot, data)
                        .then((res) => {
                            if(this.infoTraslate[0].brigada != this.worker) {

                                if(res.data.message == "success") {
                                    this.removeLoaderSpinClass()
                                    this.$emit('emitLoadItems', true)
                                    this.$emit('hideModalTraslate', false)
                                }
                            } else {
                                this.removeLoaderSpinClass()
                                toastr.info('La OT ya pertenece a esta brigada!')
                            }
                        })
                        .catch((e) => {
                            this.removeLoaderSpinClass()
                            console.log(e);
                        })
                }
            },
            loaderSpinClasss(){
                this.isLoading = !this.isLoading
                const submitButton = this.$refs.buttonLoader;
                submitButton.classList.add("disabled","spinner", "spinner-success", "spinner-left");
            },
            removeLoaderSpinClass() {
                this.isLoading = !this.isLoading
                const submitButton = this.$refs.buttonLoader;
                submitButton.classList.remove("disabled","spinner", "spinner-success", "spinner-left");
            }
        }
    }
</script>