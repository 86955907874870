<template>
  <div>
    <DxTabPanel>
      <DxItem title="Aviso" template="order-aviso-tab" v-if="aviso.length" />
      <template #order-aviso-tab>
        <OrderAvisoTab :aviso="aviso" :ot="ot" :showOt="showOt" />
      </template>

      <DxItem
        title="Bitacora"
        v-if="binnacle.length"
        template="order-bitacora-tab"
      />
      <template #order-bitacora-tab>
        <OrderBitacoraTab :binnacle="binnacle" />
      </template>

      <DxItem
        title="Informacion de precierre"
        template="order-precierre-tab"
        v-if="movil.length"
      />
      <template #order-precierre-tab>
        <OrderPrecierreTab
          :movil="movil"
          :movil_2="movil_2[0]"
          :acciones="acciones"
        />
      </template>

      <DxItem
        title="Galeria"
        template="order-galeria-tab"
        v-if="galeria.length"
      />
      <template #order-galeria-tab>
        <OrderGaleriaTab :galeria="galeria" />
      </template>

      <DxItem
        title="Materiales"
        template="order-material-tab"
        v-if="retirados.length && instalados.length"
      />
      <template #order-material-tab>
        <OrderMaterial :retirados="retirados" :instalados="instalados" />
      </template>

      <DxItem
        title="Datos de certificación"
        template="order-certification-tab"
        v-if="sync && sync.length"
      />
      <template #order-certification-tab>
        <OrderCertification :sync="sync" />
      </template>
      <DxItem
        title="Motivo rechazos"
        template="motivo-rechazos"
        v-if="rechazo && rechazo.length"
      />
      <template #motivo-rechazos>
        <OrderRechazos :rechazos="rechazo" />
      </template>
    </DxTabPanel>
  </div>
</template>

<script>
import axios from "axios";
import _ from "lodash";

import { DxTabPanel, DxItem } from "devextreme-vue/tab-panel";
import OrderAvisoTab from "./OrderAvisoTab.vue";
import OrderBitacoraTab from "./OrderBitacoraTab.vue";
import OrderPrecierreTab from "./OrderPrecierreTab.vue";
import OrderGaleriaTab from "./OrderGaleriaTab.vue";
import OrderMaterial from "./OrderMaterial.vue";
import OrderCertification from "./OrderCertification.vue";
import OrderRechazos from "./OrderRechazosTab.vue";

export default {
  components: {
    DxTabPanel,
    DxItem,
    OrderAvisoTab,
    OrderBitacoraTab,
    OrderPrecierreTab,
    OrderGaleriaTab,
    OrderMaterial,
    OrderCertification,
    OrderRechazos,
  },
  data() {
    return {
      ot: [],
      aviso: [],
      binnacle: [],
      movil: {
        causa: [],
      },
      movil_2: [],
      galeria: [],
      chart: [],
      acciones: [],
      // ---- materiales
      retirados: [],
      instalados: [],

      // datos de certificación
      sync: [],
      rechazo: [],
    };
  },
  props: {
    idOt: {
      type: Number,
      default: null,
    },
    showOt: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.getOt();
  },
  methods: {
    async getOt() {
      try {
        const response = await axios.get(`ots/${this.idOt}`);
        const [data] = response.data;
        this.rechazo = data.rechazo;
        // console.log(data);

        this.ot.push(data);

        this.ot[0].status = this.ot[0].estado_o_t_s.descripcion;
        this.instalados = data.dato_materiales ? data.dato_materiales : [];
        this.retirados = data.dato_materiales_retirados
          ? data.dato_materiales
          : [];

        let auxSync = [],
          causeSync = [
            "id",
            "ot_id",
            "anexo_cierre_codigo",
            "usuario_con_servicio",
            "causa_codigo",
            "danio",
            "accion",
            "causa_fallida",
            "causal",
            "familia",
            "tipo_incidencia",
            "tipo_instalacion",
            "cod_cierre",
            "animal_aereo",
            "sync",
            "message_response",
            "created_at",
            "updated_at",
            "tipo",
          ];
        if (data.sync) {
          let [el] = data.sync;
          for (const key in el) {
            if (!causeSync.includes(key) && !_.isObject(el[key])) {
              auxSync.push({
                titulo: key.replaceAll("_", " ").toUpperCase(),
                detalle: el[key],
              });
            }
            if (key == "animal_name") {
              if (el[key] !== "" && el[key]) {
                auxSync.push({
                  titulo: key.replaceAll("_", " ").toUpperCase(),
                  detalle: el[key],
                });
              }
            }
            if (key === "usuario_con_servicio") {
              auxSync.push({
                titulo: "USUARIO QUEDA CON SERVICIO?",
                detalle: el[key] == 1 ? "SI" : "NO",
              });
            }
          }
          this.sync = auxSync;
        }

        //prepare data aviso
        let causeW = [
          "pivot",
          "id_aviso",
          "tipo_trabajo_id",
          "icon",
          "icono",
          "causa_improcedente",
          "updated_at",
        ];
        let warning = [];

        this.aviso = data.avisos.map((item) => {
          if (item.pivot.id_aviso == item.id_aviso) {
            for (const key in item) {
              if (!causeW.includes(key) && !_.isObject(item[key])) {
                warning.push({
                  titulo: key.replaceAll("_", " ").toUpperCase(),
                  detalle: item[key],
                });
              }
              if (key == "causa_improcedente") {
                if (item[key]) {
                  warning.push({
                    titulo: "CAUSA IMPROCEDENTE",
                    detalle: item[key],
                  });
                }
              }
            }
          }
          return warning;
        });
        //data aviso
        this.aviso = this.aviso[0];

        //data bitacora
        this.binnacle = data.bitacora;

        //data precirre
        this.movil = data.movil;

        const aux = this.movil;
        this.movil_2 = aux;
        let ref = [],
          cause = [
            "principal_id",
            "causa_id",
            "pregunta_id",
            "tipo_os",
            "resultado",
            "contrata",
            "tipo_cierre",
            "obj",
            "trafo_parcial",
            "created_at",
            "updated_at",
            "deleted_at",
          ];

        if (this.movil) {
          this.movil.map((el) => {
            for (const key in el) {
              if (!cause.includes(key) && !_.isObject(el[key])) {
                ref.push({
                  titulo: key.replace("_", " ").toUpperCase(),
                  detalle: el[key],
                });
              }
              if (key == "pregunta_id") {
                ref.push({
                  titulo: "¿HUBO DAÑO?",
                  detalle: el[key] == 1 ? "NO" : "SI",
                });
              }
              if (key === "trafo_parcial") {
                ref.push({
                  titulo: "HAY " + key.replace("_", " ").toUpperCase(),
                  detalle: el[key] == 1 || el[key] == null ? "NO" : "SI",
                });
              }
            }
          });
          // data precierre - causa
          this.movil.causa = ref;
        }

        //data precierre - acciones
        this.acciones = data.acciones;

        //data galeria
        this.galeria = data.imagenes.filter((f) => f.path != "");
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
