<template>
  <div class="d-flex flex-column flex-root top-login-content">
    <div
      class="login login-signin-on login-4 d-flex flex-row-fluid"
      id="kt_login"
    >
      <div
        class="d-flex flex-center bgi-size-cover bgi-no-repeat flex-row-fluid"
        style="background-image: url(assets/media/bg/bg-2.jpg)"
      >
        <div
          class="login-form text-center text-white p-7 position-relative overflow-hidden"
        >
          <div class="d-flex flex-center mb-5">
            <a href="#" class="p-3">
              <img
                src="images/option.png"
                class="img_login max-h-125px"
                alt=""
              />
            </a>
          </div>
          <div class="login-signin">
            <div class="mb-20">
              <h3 class="opacity-40 font-weight-normal">Inicia sesión</h3>
              <p class="opacity-40">
                Bienvenido a SIPREM GD (Gestión de Daño)
              </p>
            </div>
            <div class="form">
              <div class="form-group">
                <input
                  class="form-control h-auto text-white bg-white-o-5 rounded-pill border-0 py-4 px-8"
                  type="text"
                  placeholder="Usuario"
                  name="username"
                  v-model="username"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control h-auto text-white bg-white-o-5 rounded-pill border-0 py-4 px-8"
                  type="password"
                  placeholder="Contraseña"
                  v-model="password"
                  name="password"
                  @keyup.enter="onSubmit()"
                />
              </div>
              <!-- <div class="form-group">
                <input
                  class="form-control h-auto text-white bg-white-o-5 rounded-pill border-0 py-4 px-8"
                  type="text"
                  maxlength="6"
                  placeholder="Codempresa"
                  v-model="codempresa"
                  @keyup.enter="onSubmit()"
                />
              </div> -->

              <div
                class="form-group d-flex flex-wrap justify-content-between align-items-center px-8 opacity-60"
              >
                <label
                  class="checkbox checkbox-outline checkbox-white text-white m-0"
                >
                  <input type="checkbox" name="remember" />Recordar <span></span
                ></label>
                <a
                  href="javascript:;"
                  id="kt_login_forgot"
                  class="text-white font-weight-bold"
                >
                  ¿Olvidaste tu contraseña?
                </a>
              </div>
              <div class="form-group text-center mt-10">
                <a
                  v-on:click="onSubmit()"
                  ref="kt_login_signin_submit"
                  class="btn btn-pill btn-primary opacity-90 px-15 py-3"
                >
                  {{ !isLoading ? "Ingresa" : "Ingresando" }}
                </a>
              </div>
            </div>
            <div class="mt-10">
              <span class="opacity-50 mr-4"
                >Para más información, consulta
              </span>
              <a
                href="javascript:;"
                id="kt_login_signup"
                class="text-white font-weight-normal"
              >
                Aquí
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import toastr from "toastr";
export default {
  mounted() {
    sessionStorage.removeItem("cor");
    sessionStorage.removeItem("sectores");
  },
  data() {
    return {
      isLoading: false,
      username: null,
      password: null,
      codempresa: null,
    };
  },
  methods: {
    ...mapActions({
      signIn: "auth/signIn",
    }),
    onSubmit() {
      if (!this.username || !this.password) {
        toastr.info("Debe ingresar usuario y/o contraseña");
      } else {
        this.loaderSpinClasss();
        let credentials = {
          usuario: this.username,
          password: this.password,
        //   codempresa: this.codempresa,
        };
        this.signIn(credentials)
          .then(() => {
            this.$router.push({
              name: "home",
            });
          })
          .catch(() => {
            if (!navigator.onLine) {
              toastr.info("No tiene conexión a internet");
            }
            toastr.warning("No se ha podido iniciar sesión: ");
            this.removeLoaderSpinClass();
          });
      }
    },
    loaderSpinClasss() {
      this.isLoading = !this.isLoading;
      const submitButton = this.$refs.kt_login_signin_submit;
      submitButton.classList.add(
        "disabled",
        "spinner",
        "spinner-light",
        "spinner-left"
      );
    },
    removeLoaderSpinClass() {
      this.isLoading = !this.isLoading;
      const submitButton = this.$refs.kt_login_signin_submit;
      submitButton.classList.remove(
        "disabled",
        "spinner",
        "spinner-light",
        "spinner-left"
      );
    },
  },
};
</script>
<style>
.top-login-content {
  height: 100vh !important;
}
</style>
