import * as $ from 'jquery';
const iconsCode = [
    "flaticon-placeholder-3 text-success",
    "fa-window-close text-danger",
    "fa fa-globe-americas opacity-70 text-primary",
    "flaticon-stopwatch text-warning",
    "la-truck-loading text-dark",
    "flaticon2-delivery-truck text-danger",
    "flaticon-truck font-weight-bold text-success",
    "flaticon-truck font-weight-bold text-danger",
    "flaticon-bell text-success",
    "flaticon-bell text-danger",
    "flaticon2-box text-success",
    "fa-tree text-success",
    "fas fa-database text-particular",
    "fas fa-ban text-danger",
    "la la-fire-alt text-danger"
];
const icons = () => {
    const tr = $(`#pivotgrid > div.dx-pivotgrid-container > table > tr.dx-bottom-row > 
                    td.dx-area-data-cell.dx-bottom-border > div > div > div > 
                        div > table > tbody > tr`);
    tr.each(function (index, data) {
        if (index < (tr.length - 1)) {
            $(data).find('td:eq(0)').empty().append(`<i class='fa ${iconsCode[index]}' style="font-size: 14px!important;"></i>`)
        }
    });
}
export default icons