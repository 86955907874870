import moment from 'moment'
const infoWarningManaged = (el) => {
    return ` <strong>
                <span style="color: #F64E60; font-weight: bold;">N° Orden :</span> ${el.num_aviso} <br>
                <span style="color: #F64E60; font-weight: bold;">Fecha de ingreso :</span> ${moment(el.fecha_alta).format('LLL')} <br> 
                <span style="color: #F64E60; font-weight: bold;">Tipo de aviso :</span> ${el.tipo_aviso} <br> 
                <span style="color: #F64E60; font-weight: bold;">Sector :</span> ${el.sector} <br> 
                <span style="color: #F64E60; font-weight: bold;">Subestación :</span> ${el.subestacion} <br> 
                <span style="color: #F64E60; font-weight: bold;">Tipo de trabajo :</span> ${el.tipo_trabajo.nombre} <br> 
                <span style="color: #F64E60; font-weight: bold;">Descripción:</span> ${el.observacion} <br> 
                <span style="color: #F64E60; font-weight: bold;">Referencia:</span> ${el.dir_ref} <br> 
                <span style="color: #F64E60; font-weight: bold;">Dirección:</span> ${el.dir_suscriptor}
            </strong>`
}
export default infoWarningManaged;