<template>
  <b-modal
    ref="certifcateOT-modal"
    id="certifcateOT-modal"
    v-model="showCertificate"
    size="xl"
    no-close-on-backdrop
    title="Certificar OT"
    scrollable
    hide-header-close
  >
    <b-row>
      <b-col md="6">
        <b-row>
          <b-col md="12">
            <div>
              <b-card no-body>
                <b-tabs content-class="mt-3" card small>
                  <b-tab lazy title="Información OT" active>
                    <b-row>
                      <b-col>
                        <DxDataGrid
                          :data-source="informationOT"
                          :show-borders="true"
                          :show-column-lines="true"
                          :show-row-lines="true"
                          :row-alternation-enabled="true"
                          :columnAutoWidth="true"
                          :allow-column-resizing="true"
                        >
                          <DxHeaderFilter :visible="false" />
                          <DxFilterRow :visible="false" />
                          <DxHeaderFilter :visible="true" />
                          <DxFilterRow :visible="true">
                            <DxOperationDescriptions
                              equal="Igual a"
                              notEqual="Diferente de"
                              startsWith="Empieza con"
                              endsWith="Termina con"
                              contains="Contiene"
                              between="Entre"
                              notContains="No contiene"
                              lessThanOrEqual="Menor o igual"
                              greaterThanOrEqual="Mayor o igual"
                              lessThan="Menor que"
                              greaterThan="Mayor que"
                            />
                          </DxFilterRow>
                          <DxColumn
                            data-field="num_ot"
                            caption="N° OT"
                            data-type="number"
                            alignment="center"
                          />
                          <DxColumn data-field="brigada" caption="Brigada" />
                          <DxColumn
                            data-field="fecha_asigna"
                            caption="Fecha asignación"
                            data-type="date"
                            format="dd/MM/yyyy, HH:mm:ss"
                          />
                          <DxColumn
                            data-field="observacion"
                            caption="Observaciones"
                          />
                          <DxColumn
                            data-field="estado"
                            caption="Estado"
                            cell-template="cellTemplate"
                          />
                          <template #cellTemplate="{ data: cellData }">
                            <div>
                              <i :class="cellData.data.estado_o_t_s.icono"></i>
                              {{ cellData.data.estado_o_t_s.descripcion }}
                            </div>
                          </template>
                        </DxDataGrid>
                      </b-col>
                    </b-row>
                    <b-row class="mt-3">
                      <b-col>
                        <DxDataGrid
                          :data-source="informationWarningOT"
                          :show-borders="true"
                          :show-column-lines="true"
                          :show-row-lines="true"
                          :row-alternation-enabled="true"
                          :columnAutoWidth="true"
                          :allow-column-resizing="true"
                        >
                          <DxPaging :page-size="30" />
                          <DxHeaderFilter :visible="false" />
                          <DxFilterRow :visible="true">
                            <DxOperationDescriptions
                              equal="Igual a"
                              notEqual="Diferente de"
                              startsWith="Empieza con"
                              endsWith="Termina con"
                              contains="Contiene"
                              between="Entre"
                              notContains="No contiene"
                              lessThanOrEqual="Menor o igual"
                              greaterThanOrEqual="Mayor o igual"
                              lessThan="Menor que"
                              greaterThan="Mayor que"
                            />
                          </DxFilterRow>
                          <DxSearchPanel
                            :visible="false"
                            :width="240"
                            placeholder="Buscar..."
                          />
                          <DxHeaderFilter :visible="true" />
                          <DxFilterRow :visible="true" />
                          <DxPager
                            :visible="true"
                            infoText="Página {0} of {1} ({2} registros)"
                            :allowed-page-sizes="pageSizes"
                            display-mode="full"
                            :show-page-size-selector="true"
                            :show-info="true"
                            :show-navigation-buttons="true"
                          />
                        </DxDataGrid>
                      </b-col>
                    </b-row>
                  </b-tab>
                  <b-tab lazy title="Bitacora">
                    <DxDataGrid
                      :data-source="binnacle"
                      :show-borders="true"
                      :show-column-lines="true"
                      :show-row-lines="true"
                      :row-alternation-enabled="true"
                      :columnAutoWidth="true"
                      :allow-column-resizing="true"
                    >
                      <DxPaging :page-size="10" />
                      <DxHeaderFilter :visible="true" />
                      <DxFilterRow :visible="true">
                        <DxOperationDescriptions
                          equal="Igual a"
                          notEqual="Diferente de"
                          startsWith="Empieza con"
                          endsWith="Termina con"
                          contains="Contiene"
                          between="Entre"
                          notContains="No contiene"
                          lessThanOrEqual="Menor o igual"
                          greaterThanOrEqual="Mayor o igual"
                          lessThan="Menor que"
                          greaterThan="Mayor que"
                        />
                      </DxFilterRow>
                      <DxPager
                        :visible="true"
                        infoText="Página {0} of {1} ({2} registros)"
                        :allowed-page-sizes="pageSizes"
                        display-mode="full"
                        :show-page-size-selector="true"
                        :show-info="true"
                        :show-navigation-buttons="true"
                      />
                      <DxColumn
                        data-field="fecha"
                        caption="Fecha"
                        data-type="date"
                        format="dd/MM/yyyy, HH:mm:ss"
                      />
                      <DxColumn data-field="detalle" caption="Detalle" />
                    </DxDataGrid>
                  </b-tab>
                  <b-tab
                    lazy
                    title="Información de precierre"
                    class="precierre-tab"
                    v-if="movil"
                  >
                    <div>
                      <div
                        class="alert"
                        :style="`background-color: ${color.background}; color: #FFF;`"
                      >
                        {{
                          movil_2 && movil_2.pregunta ?  movil_2.pregunta.pregunta_desc : ""
                        }}
                      </div>
                      <div>
                        <b>TIPO DE CIERRE:</b>
                        {{
                          movil_2 && movil_2.cierre ? movil_2.cierre.descripcion : "N/A"
                        }}
                      </div>
                    </div>
                    <hr />
                    <label>Observación BOL</label>
                    <textarea
                      rows="3"
                      :value="movil.observacion"
                      class="mb-3 form-control form-control-sm"
                      readonly
                    ></textarea>
                    <b-row v-if="movil.accion">
                      <b-col>
                        <b>MANO DE OBRA</b>
                        <DxDataGrid
                          :data-source="movil.accion"
                          :show-borders="true"
                          :show-column-lines="true"
                          :show-row-lines="true"
                          :row-alternation-enabled="true"
                          :columnAutoWidth="true"
                          :allow-column-resizing="true"
                        >
                          <DxHeaderFilter :visible="false" />
                          <DxFilterRow :visible="true">
                            <DxOperationDescriptions
                              equal="Igual a"
                              notEqual="Diferente de"
                              startsWith="Empieza con"
                              endsWith="Termina con"
                              contains="Contiene"
                              between="Entre"
                              notContains="No contiene"
                              lessThanOrEqual="Menor o igual"
                              greaterThanOrEqual="Mayor o igual"
                              lessThan="Menor que"
                              greaterThan="Mayor que"
                            />
                          </DxFilterRow>
                          <DxColumn
                            data-field="descripcion"
                            caption="Descripción"
                            alignment="center"
                          />
                          <DxColumn
                            data-field="fecha_ejecuta"
                            caption="Fecha ejecución"
                            data-type="date"
                            format="dd/MM/yyyy, HH:mm:ss"
                          />
                        </DxDataGrid>
                      </b-col>
                    </b-row>
                    <b-row class="mt-2">
                      <b-col>
                        <DxDataGrid
                          :data-source="movil.causa"
                          :show-borders="true"
                          :show-column-lines="true"
                          :show-row-lines="true"
                          :row-alternation-enabled="true"
                          :columnAutoWidth="true"
                          :allow-column-resizing="true"
                        >
                          <DxPaging :page-size="30" />
                          <DxHeaderFilter :visible="true" />
                          <DxFilterRow :visible="true">
                            <DxOperationDescriptions
                              equal="Igual a"
                              notEqual="Diferente de"
                              startsWith="Empieza con"
                              endsWith="Termina con"
                              contains="Contiene"
                              between="Entre"
                              notContains="No contiene"
                              lessThanOrEqual="Menor o igual"
                              greaterThanOrEqual="Mayor o igual"
                              lessThan="Menor que"
                              greaterThan="Mayor que"
                            />
                          </DxFilterRow>
                          <DxPager
                            :visible="true"
                            infoText="Página {0} of {1} ({2} registros)"
                            :allowed-page-sizes="pageSizes"
                            display-mode="full"
                            :show-page-size-selector="true"
                            :show-info="true"
                            :show-navigation-buttons="true"
                          />
                        </DxDataGrid>
                      </b-col>
                    </b-row>

                    <b-row class="mt-3" v-if="movil.reglas_oro.length != 0">
                      <b-col>
                        <label><b>REGLAS DE ORO</b></label>
                        <DxDataGrid
                          :allow-column-resizing="true"
                          :data-source="movil.reglas_oro"
                          :show-borders="true"
                          :show-column-lines="true"
                          :show-row-lines="true"
                          :row-alternation-enabled="true"
                          :columnAutoWidth="true"
                        >
                          <DxHeaderFilter :visible="true" />
                          <DxColumn data-field="codigo" caption="Código" />
                          <DxColumn
                            data-field="descripcion"
                            caption="Descripción"
                          />
                          <DxColumn
                            data-field="fechaEjecuta"
                            caption="Fecha"
                            data-type="date"
                            format="dd/MM/yyyy, HH:mm:ss"
                          />
                        </DxDataGrid>
                      </b-col>
                    </b-row>
                  </b-tab>
                  <b-tab lazy title="Materiales">
                    <b-row v-if="false">
                      <b-col>
                        <b>INFORMACIÓN DE MATERIALES</b>
                        <DxDataGrid
                          :data-source="materiales"
                          :show-borders="true"
                          :show-column-lines="true"
                          :show-row-lines="true"
                          :row-alternation-enabled="true"
                          :columnAutoWidth="true"
                          :allow-column-resizing="true"
                        >
                          <DxSearchPanel
                            :visible="false"
                            :width="240"
                            placeholder="Buscar..."
                          />
                          <DxPaging :page-size="15" />
                          <DxHeaderFilter :visible="true" />
                          <DxFilterRow :visible="true">
                            <DxOperationDescriptions
                              equal="Igual a"
                              notEqual="Diferente de"
                              startsWith="Empieza con"
                              endsWith="Termina con"
                              contains="Contiene"
                              between="Entre"
                              notContains="No contiene"
                              lessThanOrEqual="Menor o igual"
                              greaterThanOrEqual="Mayor o igual"
                              lessThan="Menor que"
                              greaterThan="Mayor que"
                            />
                          </DxFilterRow>
                          <DxPager
                            :visible="false"
                            infoText="Página {0} of {1} ({2} registros)"
                            :allowed-page-sizes="pageSizes"
                            display-mode="full"
                            :show-page-size-selector="true"
                            :show-info="true"
                            :show-navigation-buttons="true"
                          />
                          <DxColumn data-field="codmaterial" caption="Código" />
                          <DxColumn
                            data-field="observaciones"
                            caption="Detalle"
                          />
                        </DxDataGrid>
                      </b-col>
                    </b-row>
                    <b-row v-if="retirados && retirados.length">
                      <b-col>
                        <b>MATERIALES RETIRADOS</b>
                        <DxDataGrid
                          :data-source="retirados"
                          :show-borders="true"
                          :show-column-lines="true"
                          :show-row-lines="true"
                          :row-alternation-enabled="true"
                          :columnAutoWidth="true"
                          :allow-column-resizing="true"
                        >
                          <DxGroupPanel
                            :visible="true"
                            emptyPanelText="Arrastre una columna aquí para agrupar"
                          />
                          <DxSearchPanel
                            :visible="false"
                            :width="240"
                            placeholder="Buscar..."
                          />
                          <DxPaging :page-size="15" />
                          <DxHeaderFilter :visible="true" />
                          <DxFilterRow :visible="true">
                            <DxOperationDescriptions
                              equal="Igual a"
                              notEqual="Diferente de"
                              startsWith="Empieza con"
                              endsWith="Termina con"
                              contains="Contiene"
                              between="Entre"
                              notContains="No contiene"
                              lessThanOrEqual="Menor o igual"
                              greaterThanOrEqual="Mayor o igual"
                              lessThan="Menor que"
                              greaterThan="Mayor que"
                            />
                          </DxFilterRow>
                          <DxPager
                            :visible="false"
                            infoText="Página {0} of {1} ({2} registros)"
                            :allowed-page-sizes="pageSizes"
                            display-mode="full"
                            :show-page-size-selector="true"
                            :show-info="true"
                            :show-navigation-buttons="true"
                          />
                          <DxColumn
                            data-field="cod_articulo"
                            caption="Código"
                            alignment="center"
                          />
                          <DxColumn
                            data-field="articulo.descripcion"
                            :group-index="0"
                            caption="Articulo"
                          />
                          <DxColumn
                            data-field="cod_ucc"
                            caption="UCC"
                            alignment="center"
                          />
                          <DxColumn
                            data-field="cantidad"
                            caption="Cantidad"
                            alignment="center"
                          />
                          <DxSummary>
                            <DxTotalItem
                              column="cantidad"
                              summary-type="count"
                              display-format="Total: {0}" />
                            <DxGroupItem
                              :align-by-column="true"
                              column="cantidad"
                              summary-type="count"
                              display-format="{0}"
                          /></DxSummary>
                        </DxDataGrid>
                      </b-col>
                    </b-row>
                    <b-row v-else>
                      <b-col class="text-center">
                        <img src="assets/img/empty.png" alt="Empty" />
                        <p class="text-muted">No hay materiales retirados</p>
                      </b-col>
                    </b-row>

                    <b-row v-if="instalados && instalados.length">
                      <b-col>
                        <b>MATERIALES INSTALADOS</b>
                        <DxDataGrid
                          :data-source="instalados"
                          :show-borders="true"
                          :show-column-lines="true"
                          :show-row-lines="true"
                          :row-alternation-enabled="true"
                          :columnAutoWidth="true"
                          :allow-column-resizing="true"
                        >
                          <DxGroupPanel
                            :visible="true"
                            emptyPanelText="Arrastre una columna aquí para agrupar"
                          />
                          <DxSearchPanel
                            :visible="false"
                            :width="240"
                            placeholder="Buscar..."
                          />
                          <DxPaging :page-size="15" />
                          <DxHeaderFilter :visible="true" />
                          <DxFilterRow :visible="true">
                            <DxOperationDescriptions
                              equal="Igual a"
                              notEqual="Diferente de"
                              startsWith="Empieza con"
                              endsWith="Termina con"
                              contains="Contiene"
                              between="Entre"
                              notContains="No contiene"
                              lessThanOrEqual="Menor o igual"
                              greaterThanOrEqual="Mayor o igual"
                              lessThan="Menor que"
                              greaterThan="Mayor que"
                            />
                          </DxFilterRow>
                          <DxPager
                            :visible="false"
                            infoText="Página {0} of {1} ({2} registros)"
                            :allowed-page-sizes="pageSizes"
                            display-mode="full"
                            :show-page-size-selector="true"
                            :show-info="true"
                            :show-navigation-buttons="true"
                          />
                          <DxColumn
                            data-field="cod_articulo"
                            caption="Código"
                          />
                          <DxColumn
                            data-field="articulo.descripcion"
                            caption="Articulo"
                          />
                          <DxColumn data-field="cod_ucc" caption="UCC" />
                          <DxColumn
                            data-field="cantidad"
                            caption="Cantidad"
                            alignment="center"
                          />
                        </DxDataGrid>
                      </b-col>
                    </b-row>
                    <b-row v-else>
                      <b-col class="text-center">
                        <img src="assets/img/empty.png" alt="Empty" />
                        <p class="text-muted">No hay materiales instalados</p>
                      </b-col>
                    </b-row>
                  </b-tab>
                  <b-tab lazy title="Motivo rechazos" v-if="rechazos.length">
                    <DxDataGrid
                      :data-source="rechazos"
                      :show-borders="true"
                      :show-column-lines="true"
                      :show-row-lines="true"
                      :row-alternation-enabled="true"
                      :columnAutoWidth="true"
                      :allow-column-resizing="true"
                    >
                      <DxSearchPanel
                        :visible="false"
                        :width="240"
                        placeholder="Buscar..."
                      />
                      <DxPaging :page-size="15" />
                      <DxHeaderFilter :visible="true" />
                      <DxFilterRow :visible="true">
                        <DxOperationDescriptions
                          equal="Igual a"
                          notEqual="Diferente de"
                          startsWith="Empieza con"
                          endsWith="Termina con"
                          contains="Contiene"
                          between="Entre"
                          notContains="No contiene"
                          lessThanOrEqual="Menor o igual"
                          greaterThanOrEqual="Mayor o igual"
                          lessThan="Menor que"
                          greaterThan="Mayor que"
                        />
                      </DxFilterRow>
                      <DxPager
                        :visible="false"
                        infoText="Página {0} of {1} ({2} registros)"
                        :allowed-page-sizes="pageSizes"
                        display-mode="full"
                        :show-page-size-selector="true"
                        :show-info="true"
                        :show-navigation-buttons="true"
                      />
                      <DxColumn data-field="brigada" caption="Brigada" />
                      <DxColumn
                        data-field="motivo.descripcion"
                        caption="Motivo"
                      />
                      <DxColumn
                        data-field="observacion"
                        caption="Observación brigada"
                        width="400"
                      />
                      <DxColumn
                        data-field="fecha_rechazo"
                        caption="Fecha rechazo"
                        data-type="date"
                        format="dd/MM/yyyy, HH:mm:ss"
                      />
                    </DxDataGrid>
                  </b-tab>
                  <b-tab lazy title="Galería" v-if="images && images.length">
                    <b-container fluid class="p-4 bg-secondary">
                      <b-row>
                        <b-col
                          md="6"
                          class="mb-3"
                          v-for="(item, index) in images"
                          :key="index"
                        >
                          <b-img
                            thumbnail
                            fluid
                            :src="servidor + item.path"
                            :alt="item.nombre"
                          ></b-img>
                        </b-col>
                      </b-row>
                    </b-container>
                  </b-tab>
                </b-tabs>
              </b-card>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="6">
        <b-row>
          <b-col md="12">
            <b-row>
              <b-col>
                <div
                  :class="
                    'form-text ' +
                    (data.trafo.id !== 1 && data.trafo.id != -1
                      ? 'text-success'
                      : 'text-danger')
                  "
                >
                  {{
                    data.trafo.id !== 1 && data.trafo.id != -1
                      ? "Esta orden tiene información de trafo parcial"
                      : "No se tiene información de trafo parcial para esta Orden"
                  }}
                </div>
                <transition name="fade">
                  <b-row v-if="data.trafo.id !== 1" class="mt-2">
                    <b-col>
                      <label
                        >Fecha apertura:
                        <b>{{ trafo.inicio | dateT }}</b></label
                      >
                    </b-col>
                    <b-col>
                      <label
                        >Fecha cierre: <b>{{ trafo.fin | dateT }}</b>
                      </label>
                    </b-col>
                  </b-row>
                </transition>
              </b-col>
            </b-row>
          </b-col>

          <b-col md="12" class="mt-2">
            <hr />
            <p>Certificar OT</p>
            <b-form-group>
              <b-form-textarea
                size="sm"
                rows="3"
                v-model="data.observacion_cor"
              />
            </b-form-group>
          </b-col>
          <b-col md="4" class="mb-3">
            <div class="checkbox-inline">
              <label
                :class="`checkbox checkbox-outline checkbox-success ${
                  disabled ? 'checkbox-disabled' : ''
                }`"
              >
                <input
                  type="checkbox"
                  value="1"
                  :disabled="disabled"
                  v-model="form.certificate.checked"
                  @change="checkboxSelect(1)"
                />
                <span></span> Certificar OT
              </label>
            </div>
          </b-col>
          <b-col md="4" class="mb-3">
            <div class="checkbox-inline">
              <label
                :class="`checkbox checkbox-outline checkbox-danger ${
                  disabledImp ? 'checkbox-disabled' : ''
                }`"
              >
                <input
                  type="checkbox"
                  value="3"
                  :disabled="disabledImp"
                  v-model="valueChecked.check"
                  @change="checkboxSelect(3)"
                />
                <span></span> Improcedente
              </label>
            </div>
          </b-col>

          <b-col md="4" class="mb-3">
            <div class="checkbox-inline">
              <label
                :class="`checkbox checkbox-outline checkbox-danger ${
                  disabledAv ? 'checkbox-disabled' : ''
                }`"
              >
                <input
                  value="2"
                  type="checkbox"
                  :disabled="disabledAv"
                  v-model="form.check.checked"
                  @change="checkboxSelect(2)"
                />
                <span></span> Aviso pendiente
              </label>
            </div>
          </b-col>
          <transition name="component-fade" mode="out-in">
            <b-col v-if="form.certificate.value == 1">
              <b-row class="bg-secondary p-3">
                <b-col class="mb-2">
                  <label>Acción</label>
                  <b-form-select
                    @change="searchAction2('accion')"
                    v-model="value.accion"
                    :options="actions"
                    size="sm"
                  />
                </b-col>

                <b-col
                  md="6"
                  class="mb-1"
                  v-for="(item, index) in items"
                  :key="index"
                >
                  <div v-show="item.vmodel">
                    <label> {{ item.label }} </label>
                    <b-form-select
                      :disabled="!item.show"
                      @change="searchAction2(item.vmodel)"
                      v-model="value[item.vmodel]"
                      :options="item.options"
                      size="sm"
                    />
                  </div>
                  <transition name="fade">
                    <div
                      v-if="
                        formSearch.tipo_instalacion == 3 &&
                        item.vmodel == 'tipo_instalacion'
                      "
                      class="mt-2"
                    >
                      <b-row>
                        <b-col>
                          <label for="">Hora de inicio</label>
                          <input
                            v-model="data.trafo.inicio"
                            type="datetime-local"
                            class="form-control form-control-sm mb-1"
                            :min="informationWarningOT.fecha_alta | withDateT"
                            :max="today"
                          />
                          <p :class="isAfterBegin.class">
                            <span>
                              {{ isAfterBegin.message }}
                            </span>
                          </p>
                        </b-col>
                        <b-col>
                          <label for="">Hora fin</label>
                          <input
                            v-model="data.trafo.fin"
                            :min="data.trafo.inicio"
                            :max="today"
                            type="datetime-local"
                            class="form-control form-control-sm mb-1"
                          />
                          <p :class="isAfter.class">
                            <span>
                              {{ isAfter.message }}
                            </span>
                          </p>
                        </b-col>
                      </b-row>
                    </div>
                    <div
                      v-if="
                        formSearch.tipo_instalacion !== 3 &&
                        formSearch.tipo_instalacion !== 0 &&
                        formSearch.tipo_instalacion !== null &&
                        data.trafo.id !== 1 &&
                        item.vmodel == 'tipo_instalacion'
                      "
                    >
                      <label class="text-danger mt-2">
                        <i class="la la-warning text-warning"></i> ¡¡ Hay
                        información de trafo parcial !!
                      </label>
                    </div>
                  </transition>
                </b-col>
                <b-col md="12" v-if="formSearch.tipo_incidencia == 4">
                  <label>Lista incidencia </label>
                  <Select2
                    class="select2 mb-2"
                    v-model="moop"
                    :options="optionsTipInc"
                    placeholder="Filtrar"
                  />
                </b-col>
                <b-col md="12" v-if="formSearch.causal == 57">
                  <label>Seleccione animal aéreo </label>
                  <Select2
                    class="select2 mb-3"
                    v-model="ave"
                    :options="options"
                    placeholder="Filtrar"
                  />
                </b-col>
              </b-row>
            </b-col>
            <b-col
              md="12"
              class="bg-secondary p-4"
              v-else-if="form.check.value == 2"
            >
              <b-row>
                <b-col md="12" class="mt-4">
                  <label>Motivo</label>
                  <select class="form-control form-control-sm" v-model="causa">
                    <option
                      :value="item.id"
                      v-for="(item, idx) in list"
                      :key="idx"
                    >
                      {{ item.causa }}
                    </option>
                  </select>
                </b-col>
                <b-col>
                  <label>Observación</label>
                  <textarea
                    cols="6"
                    rows="6"
                    v-model="observacion"
                    class="form-control form-control-sm"
                  ></textarea>
                </b-col>
              </b-row>
            </b-col>
            <b-col v-else-if="valueChecked.value == 3">
              <Improper
                ref="IMP"
                :information="infoimp"
                @improcedente="improcedente"
              />
            </b-col>
          </transition>
        </b-row>
      </b-col>
    </b-row>
    <template #modal-footer>
      <button
        @click="showCertificate = false"
        class="btn btn-sm btn-link text-dark opacity-70"
      >
        <i class="la la-reply"></i> Cancelar
      </button>
      <b-button
        variant="outline-primary"
        size="sm"
        v-if="form.check.checked"
        @click="reabrirCaso()"
      >
        <i class="la la-save"></i> {{ loading ? "Cargando..." : "Guardar" }}
      </b-button>
      <b-button
        ref="buttonLoader"
        variant="primary"
        size="sm"
        @click="certify()"
        v-if="form.certificate.checked"
      >
        <i class="la la-certificate"></i> Certificar
      </b-button>
    </template>
  </b-modal>
</template>
<style>
.card .tabs .tab-content {
  height: 50vh;
  overflow-y: auto;
}
.tab-pane .table-pane {
  font-size: 0.85rem !important;
}
.trafo {
  border: 1px solid #c7c7c7;
  border-radius: 10px;
}
.precierre-tab {
  padding: 0.75rem !important;
}
</style>
<script>
import Select2 from "v-select2-component";
import axios from "axios";
import moment from "moment";
import toastr from "toastr";
import * as $ from "jquery";
import { mapGetters } from "vuex";
import _ from "lodash";
import {
  DxDataGrid,
  DxPaging,
  DxColumn,
  DxSearchPanel,
  DxFilterRow,
  DxHeaderFilter,
  DxOperationDescriptions,
  DxPager,
  DxGroupPanel,
  DxSummary,
  DxGroupItem,
  DxTotalItem,
} from "devextreme-vue/data-grid";
import ENV from "@/environment";
import Improper from "../ImproperWarning.vue";
export default {
  name: "ModalCertificateOT",
  components: {
    Select2,
    DxDataGrid,
    DxPaging,
    DxColumn,
    DxSearchPanel,
    DxFilterRow,
    DxHeaderFilter,
    DxPager,
    DxGroupPanel,
    DxOperationDescriptions,
    DxSummary,
    DxGroupItem,
    DxTotalItem,
    Improper,
  },
  props: {
    causal: {
      type: Array,
      default: () => [],
    },
    observation: {
      type: String,
      default: () => "",
    },
    causas: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      servidor:
        process.env.NODE_ENV == "development" ? ENV.SERVER_DEV : ENV.SERVER,
      // servidor: "http://138.197.75.131:9000",
      pageSizes: [5, 10, 15, 30],
      num_ot: null,
      id_ot: 0,
      info_aviso: null,
      brigada: "",
      disabled: false,
      disabledImp: false,
      disabledAv: false,
      informationOT: {
        brigada: "",
        created_at: "",
        estado_o_t_s: "",
        fecha_asigna: "",
        geojson: "",
        id: "",
        id_estado: "",
        id_ot: "",
        num_ot: "",
        observacion: "",
        sync_certificar: "",
        updated_at: "",
        usuario_genera: "",
      },
      informationWarningOT: [],
      binnacle: [],
      movil: {
        causa: [],
        reglas_oro: [],
        accion: [],
      },
      showCertificate: false,
      options: [],
      form: {
        check: {
          checked: false,
          value: null,
        },
        certificate: {
          checked: false,
          value: null,
        },
      },
      settings: {
        minimumResultsForSearch: Infinity,
      },
      isLoading: false,
      data: {
        cod_cierre: 1,
        num_ot: 0,
        usuario_servicio: null,
        causa: null,
        fecha_asigna: 0,
        fecha_recibe: null,
        fecha_arribo: null,
        fecha_ot_fallida: null,
        fecha_localiza_causa: null,
        fecha_resolucion: null,
        observacion_cor: "",
        observacion_bol: null,
        brigada: 0,
        danio: null,
        accion: null,
        usuario: null,
        informacion: {},
        trafo: {
          id: 1,
          inicio: null,
          fin: null,
        },
      },
      trafo: {
        inicio: null,
        fin: null,
      },
      actions: [
        {
          value: -1,
          text: "Seleccione",
        },
        {
          value: 1,
          text: "Crear orden de trabajo",
        },
        {
          value: 2,
          text: "Crear incidencia",
        },
      ],
      formSearch: {
        accion: -1,
        tipo_incidencia: null,
        usuario_servicio: null,
        tipo_instalacion: null,
        familia: null,
        tipo: null,
        causal: null,
        causa_fallida: null,
      },
      items: {
        tipo_incidencia: {
          id: 1,
          show: false,
        },
        usuario_servicio: {
          id: 2,
          show: false,
        },
        tipo_instalacion: {
          id: 3,
          show: false,
        },
        familia: {
          id: 4,
          show: false,
        },
        tipo: {
          id: 5,
          show: false,
        },
        causal: {
          id: 6,
          show: false,
        },
        causa_fallida: {
          id: 7,
          show: false,
        },
      },
      value: {
        accion: -1,
        tipo_incidencia: 0,
        usuario_servicio: 0,
        tipo_instalacion: 0,
        familia: 0,
        tipo: 0,
        causal: 0,
        causa_fallida: 0,
      },
      levels: {
        parent: null,
        accion: null,
        description: null,
        data: [],
        model: null,
      },
      level_TI: {
        descripcion: null,
        accion: null,
        parent: null,
        description: null,
        data: [],
        model: null,
      },
      level_US: {
        descripcion: null,
        accion: null,
        parent: null,
        description: null,
        data: [],
        model: null,
      },
      ave: -1,
      images: [],
      optionsTipInc: [],
      moop: -1,
      materiales: [],
      retirados: [],
      instalados: [],

      current: {},
      causasList: [],
      causa_movil: null,
      acciones: [],
      pendiente: [],
      rechazos: [],
      //causas reabrir caso
      list: [],
      causa: -1,
      observacion: "",
      loading: false,
      valueChecked: {
        check: false,
        value: null,
      },
      infoimp: {},
      avs: [],
      movil_2: null,
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    color() {
      if (this.movil_2) {
        let color = {};
        if (this.movil_2.pregunta_id == 1) {
          color = {
            background: "#AD631D",
            color: "#FFF",
          };
        } else if (this.movil_2.pregunta_id == 2) {
          color = {
            background: "#185967",
            color: "#FFF",
          };
        } else if (this.movil_2.pregunta_id == 3) {
          color = {
            background: "#156C5A",
            color: "#FFF",
          };
        } else if (this.movil_2.pregunta_id == 4) {
          color = {
            background: "#9V7910",
            color: "#FFF",
          };
        } else if (this.movil_2.pregunta_id == 5) {
          color = {
            background: "#5B3071",
            color: "#FFF",
          };
        } else if (this.movil_2.pregunta_id == 6) {
          color = {
            background: "#189E85",
            color: "#FFF",
          };
        }

        return color;
      } else {
        return {
          background: "#FFF",
          color: "#333",
        };
      }
    },
    today() {
      let date = moment().toISOString().split(".")[0];
      date = date.substring(0, date.length - 3);
      return date;
    },
    isAfterBegin() {
      /********************************************************************************
       ************* CONSIDERACIONES PARA VALIDACIÓN DE FECHA DE APERTURA  ************
       ** No puede ser menor a la fecha/hora de entrada del aviso (fecha_alta)
       ** No puede ser mayor a la fecha/hora actual
       ** Se manejan mensajes de advertencia para fechas no válidas
       ********************************************************************************/
      // let init = this.informationWarningOT.fecha_alta;
      let init = this.data.trafo.inicio;
      let end = this.data.trafo.fin;
      if (
        moment(end).format("YYYY-MM-DD HH:mm:ss") <
        moment(init).format("YYYY-MM-DD HH:mm:ss")
      ) {
        console.log(
          moment(init).format("YYYY-MM-DD HH:mm:ss"),
          moment(end).format("YYYY-MM-DD HH:mm:ss")
        );
        return {
          message:
            "La fecha de apertura seleccionada es menor a la fecha de ingreso del aviso",
          error: true,
          class: "text-danger",
        };
      } else if (
        moment(end).format("YYYY-MM-DD HH:mm:ss") >
        moment().format("YYYY-MM-DD HH:mm:ss")
      ) {
        return {
          message:
            "La fecha de apertura seleccionada no puede ser mayor a la fecha/hora actual",
          error: true,
          class: "text-danger",
        };
      } else {
        return {
          message: "Fecha válida",
          error: false,
          class: "text-success",
        };
      }
    },
    isAfter() {
      /***************************************************************************************
       *********** CONSIDERACIONES PARA VALIDACIÓN DE FECHA DE CIERRE  ***********
       ** ------ La fecha no puede ser menor o igual a la fecha/hora de apertura (data.trafo.inicio)
       ** ------ Al menos un minuto de diferencia entre fecha de apertura y fecha de cierre
       ** ------ Fecha/hora de cierre no puede ser mayor a la fecha actual
       ** ------ Se manejan mensajes de advertencia para fechas no válidas
       ***************************************************************************************/
      let init = this.data.trafo.inicio;
      let end = this.data.trafo.fin;

      if (
        moment(end).format("YYYY-MM-DD HH:mm:ss") <=
        moment(init).format("YYYY-MM-DD HH:mm:ss")
      ) {
        return {
          message:
            "La fecha de cierre seleccionada es menor a la fecha de apertura",
          error: true,
          class: "text-danger",
        };
      } else if (
        moment(end).format("YYYY-MM-DD HH:mm:ss") >
        moment().format("YYYY-MM-DD HH:mm:ss")
      ) {
        return {
          message:
            "La fecha de cierre seleccionada no puede ser mayor a la fecha actual",
          error: true,
          class: "text-danger",
        };
      } else {
        return {
          message: "Fecha válida",
          error: false,
          class: "text-success",
        };
      }
    },
  },
  mounted() {
    this.configOptionSelect();
    this.loadAviso();
  },
  watch: {
    current() {
      if (this.current.id_estado == 9) {
        this.loadCausas();
      }
    },
  },
  filters: {
    replace_(values) {
      return values.replace("_", " ");
    },
    upperCaseWithReplace(value) {
      return value.replace(/_/g, " ").toUpperCase();
    },
    upperCase(values) {
      return values.toUpperCase();
    },
    format(values) {
      return moment(values).format("LLL");
    },
    simpleFormat(value) {
      return moment(value).format("l") + " " + moment(value).format("LTS");
    },
    dateT(value) {
      return moment(value.replace("T", " ")).format("DD/MM/YYYY HH:mm:ss");
    },
    withDateT(value) {
      if (value) {
        return value.replace(" ", "T");
      }
      return "";
    },
  },
  methods: {
    reabrirCaso() {
      if (this.observacion === "" || this.causa == -1) {
        toastr.info("Complete la información");
      } else {
        this.loading = true;
        let data = {
          causa_id: this.causa,
          num_ot: this.num_ot,
          observacion: this.observacion,
          usuario_genera: this.user.usuario,
        };
        axios.post("aviso-pendiente", data).then((res) => {
          if (!res.data.error) {
            this.$emit("loadMenu", true);
            // this.$root.$children[0].$children[1].$children[0].loadMenu();
            this.loading = false;
            this.showCertificate = false;
          } else {
            toastr.info(res.data.message);
          }
        });
      }
    },
    loadCausasPendiente() {
      axios.get("causa-pendiente").then((res) => {
        this.list = _.filter(res.data, function (o) {
          return o.isCertificate;
        });
        this.list.push({
          id: -1,
          causa: "Seleccione",
        });
        this.causa = -1;
      });
    },
    loadCausas() {
      axios.get("causa").then((res) => {
        this.causasList = res.data;
      });
    },
    loadAviso() {
      axios.get("ots").then((res) => {
        this.optionsTipInc = [];
        this.optionsTipInc.push({ id: -1, text: `Seleccione` });
        res.data.map((el) => {
          if (
            /**
             * 1. Aviso
             * 2. Incidencia
             * 3. Descargo
             */
            el.tipo_trabajo_id == 2 &&
            el.estado == 2 &&
            (el.id_estado == 1 ||
              el.id_estado == 2 ||
              el.id_estado == 3 ||
              el.id_estado == 4)
          ) {
            this.optionsTipInc.push({
              id: el.num_aviso,
              text: `OT ${el.num_ot} - ${el.num_aviso}`,
            });
          }
        });
      });
    },
    reset() {
      this.rechazos = [];
      this.formSearch = {
        accion: -1,
        tipo_incidencia: null,
        usuario_servicio: null,
        tipo_instalacion: null,
        familia: null,
        tipo: null,
        causal: null,
        causa_fallida: null,
      };
      this.observacion = "";
      this.list = [];
      this.causa = -1;
      this.data.trafo = {
        id: 1,
        inicio: null,
        fin: null,
      };
      this.valueChecked = {
        check: false,
        value: null,
      };
      this.infoimp = {};
      this.avs = [];
    },
    searchImages(id) {
      axios.get(`principal-images/${id}`).then((res) => {
        this.images = res.data;
      });
    },
    configOptionSelect() {
      this.options = [];
      this.options.push({
        id: -1,
        text: "Seleccione",
      });
      axios.get("/aves").then((res) => {
        res.data.map((el) => {
          this.options.push({
            value: el.cdmattoaves,
            id: el.codigo,
            text: el.nombreave,
          });
        });
      });
    },
    hideModalCertificate() {
      this.checkWar = null;
      this.$emit("hideModalCertificate", false);
    },
    checkboxSelect(check) {
      $("#certifcateOT-modal___BV_modal_content_").attr("tabindex", null);

      switch (check) {
        case 1:
          this.form.certificate.value =
            this.form.certificate.value == check ? null : check;
          this.form.check.value = null;
          this.form.check.checked = false;
          this.valueChecked.value = null;
          this.valueChecked.check = false;
          this.value.accion = -1;
          this.searchAction2("accion");
          break;

        case 2:
          this.form.check.value = this.form.check.value == check ? null : check;
          this.form.certificate.value = null;
          this.form.certificate.checked = false;
          this.valueChecked.value = null;
          this.valueChecked.check = false;
          break;
        case 3:
          this.form.check.value = null;
          this.form.check.checked = false;
          this.form.certificate.value = null;
          this.form.certificate.checked = false;
          // var IMP = this.$refs.IMP;
          this.infoimp = {};

          this.infoimp = {
            ot: this.informationOT[0].num_ot,
            num_aviso: this.avs,
          };
          // console.log(this.informationOT);
          // IMP.ot = this.informationOT[0].num_ot;
          // IMP.searchAv(this.informationOT[0].id_ot)
          this.valueChecked.value =
            this.valueChecked.value == check ? null : check;
          // IMP.ot = this.
          break;
      }
    },
    certify() {
      let F = this.formSearch;
      let R = this.data;
      R.accion = 0;
      R.causa_fallida = F.causa_fallida;
      R.causal = F.causal;
      R.familia = F.familia;
      R.tipo = F.tipo;
      R.tipo_incidencia = F.tipo_incidencia;
      R.tipo_instalacion = F.tipo_instalacion;
      R.usuario_con_servicio = F.usuario_servicio;
      R.animal_aereo = this.ave;
      R.incidencia = parseInt(this.moop);
      if (this.value.accion == 1) {
        R.cod_cierre = 11;
      } else if (this.value.accion == 2) {
        if (F.tipo_incidencia == 1) {
          R.cod_cierre = F.tipo_instalacion == 1 ? 22 : 23;
        }
        if (F.tipo_incidencia == 2) {
          R.cod_cierre = 31;
        }
        if (F.tipo_incidencia == 3) {
          R.cod_cierre = F.causa_fallida;
        }
      }
      if (this.current.id_estado && this.current.id_estado == 9) {
        R.fecha_recibe = moment().format("YYYY/MM/DD HH:mm:ss");
        R.fecha_arribo = moment().format("YYYY/MM/DD HH:mm:ss");
        R.fecha_localiza_causa = moment().format("YYYY/MM/DD HH:mm:ss");
        R.fecha_resolucion = moment().format("YYYY/MM/DD HH:mm:ss");
        R.fecha_causa = moment().format("YYYY/MM/DD HH:mm:ss");
        R.observacion_bol = R.observacion_cor.replace(/\n/g, "\n");
        R.observacion_cor = R.observacion_cor.replace(/\n/g, "\n");
        R.danio = 1;
        R.causa = this.causa_movil;

        if (!this.data.causa) {
          if (R.cod_cierre == 11) {
            this.data.causa = 889;
          } else {
            this.data.causa = R.cod_cierre;
          }
        }
      }
      if (F.tipo_incidencia == 4) {
        R.moop = "1";
      }
      if (R.brigada === "" || R.brigada == null) {
        R.brigada = 123456789;
      }
      if (this.pendiente && this.pendiente.length) {
        let text = "\n";

        this.pendiente.map((el) => {
          text += el.observacion + "\n\n\n";
        });
        text.replace(/[{()}]/g, "");
        R.extra = text;
      }
      if (this.validate()) {
        if (this.data.trafo.id !== 1 && this.formSearch.tipo_instalacion == 3) {
          if (!this.isAfterBegin.error && !this.isAfter.error) {
            /**
             * VALIDACIÓN
             * SI HAY INFORMACIÓN DE TRAFO PARCIAL Y SE SELECCIONA TRAFO PARCIAL (TIPO DE INSTALACIÓN)
             * SE PROCEDE
             */
            R.fecha_localiza_causa = moment(this.data.trafo.inicio).format(
              "YYYY/MM/DD HH:mm:ss"
            );
            R.fecha_resolucion = moment(this.data.trafo.fin).format(
              "YYYY/MM/DD HH:mm:ss"
            );
            this.loaderSpinClasss();
            axios
              .post("ots/certify", R)
              .then((res) => {
                this.removeLoaderSpinClass();
                if (!res.data.error) {
                  toastr.success(res.data.message);
                  this.showCertificate = false;
                  this.$emit("hideModalCertificate", false);
                } else {
                  toastr.info(res.data.message);
                }
              })
              .catch((e) => {
                console.log(e);
              });
          } else {
            if (this.isAfterBegin.error) {
              toastr.info(this.isAfterBegin.message);
            }
            if (this.isAfter.error) {
              toastr.info(this.isAfter.message);
            }
          }
        } else if (
          this.data.trafo.id !== 1 &&
          this.formSearch.tipo_instalacion != 3
        ) {
          /**
           * VALIDACIÓN
           * SI HAY INFORMACIÓN DE TRAFO PARCIAL Y SE SELECCIONA OPCIÓN DIFERENTE A TRAFO PARCIAL (TIPO DE INSTALACIÓN)
           * SE PROCEDE CON UNA CONFIRMACIÓN ANTES DE CERTIFICAR
           */
          this.$swal
            .fire({
              title: "Información",
              text: "Importante, hay información de trafo parcial para esta Orden, ¿Desea continuar con la certificación?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Confirmar",
            })
            .then((result) => {
              // console.log(result);
              if (result.isConfirmed) {
                this.loaderSpinClasss();
                axios
                  .post("ots/certify", R)
                  .then((res) => {
                    this.removeLoaderSpinClass();
                    if (res.data.message == "success") {
                      toastr.success(res.data.message);
                      this.showCertificate = false;
                      this.$emit("hideModalCertificate", false);
                    } else {
                      toastr.info(res.data.message);
                    }
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              }
            });
        } else if (
          this.data.trafo.id == 1 &&
          this.formSearch.tipo_instalacion === 3
        ) {
          /**
           * VALIDACIÓN
           * SI NO EXISTE INFORMACIÓN DE TRAFO PARCIAL
           * PERO EL OPERADOR DIGITA LA INFORMACIÓN DE FORMA MANUAL
           */
          this.loaderSpinClasss();
          R.fecha_localiza_causa = moment(this.data.trafo.inicio).format(
            "YYYY/MM/DD HH:mm:ss"
          );
          R.fecha_resolucion = moment(this.data.trafo.fin).format(
            "YYYY/MM/DD HH:mm:ss"
          );
          axios
            .post("ots/certify", R)
            .then((res) => {
              this.removeLoaderSpinClass();
              if (res.data.message == "success") {
                toastr.success(res.data.message);
                this.showCertificate = false;
                this.$emit("hideModalCertificate", false);
              } else {
                toastr.info(res.data.message);
              }
            })
            .catch((e) => {
              console.log(e);
            });
        } else {
          /**
           * VALIDACIÓN
           * SI NO HAY INFORMACIÓN DE TRAFO PARCIAL Y NO SE SELECCIONA ESTA OPCIÓN EN TIPO DE INSTALACIÓN
           */
          this.loaderSpinClasss();
          axios
            .post("ots/certify", R)
            .then((res) => {
              this.removeLoaderSpinClass();
              if (res.data.message == "success") {
                toastr.success(res.data.message);
                this.showCertificate = false;
                this.$emit("hideModalCertificate", false);
              } else {
                toastr.info(res.data.message);
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }
      }
    },
    searchAction2(vmodal) {
      $("#certifcate-modal___BV_modal_content_").attr("tabindex", null);
      this.formSearch[vmodal] = this.value[vmodal];
      let idChange = 0;
      if (vmodal != "accion") idChange = this.items[vmodal].id;
      for (let item in this.items) {
        if (this.items[item].id > idChange) {
          this.items[item].show = false;
          this.value[item] = null;
          this.formSearch[item] = null;
        }
      }
      if (this.formSearch.accion != -1 && vmodal != "causa_fallida") {
        axios.post("createMatrizCts", this.formSearch).then((res) => {
          let vmodelNext = res.data.vmodelNext;
          let data = res.data.data;
          if (data.length != 0) {
            if (vmodelNext != "") {
              let dataOptions = [];
              for (let c of data) {
                dataOptions.push({
                  value: c.id,
                  text: c.descripcion,
                });
              }
              let title = res.data.descripcion ? res.data.descripcion : "title";
              let idVmodalNext = this.items[vmodelNext].id;
              this.items[vmodelNext] = {
                id: idVmodalNext,
                vmodel: vmodelNext,
                show: true,
                label: title,
                options: dataOptions,
              };
            }
          } else {
            this.formSearch[vmodelNext] = 0;
            this.value[vmodelNext] = 0;
            if (vmodelNext != "causa_fallida") this.searchAction2(vmodelNext);
          }
        });
      }
    },
    validate() {
      let R = this.data;
      let result = true;
      if (R.cod_cierre == -1) {
        result = false;
        toastr.info("Debe seleccionar una acción");
      }

      if (R.observacion_cor == "" || R.observacion_cor == null) {
        result = false;
        toastr.info("Se require alguna observación para continuar");
      }
      if (this.formSearch.accion == 2) {
        for (let c in this.formSearch) {
          if (this.formSearch[c] == null) {
            result = false;
            if (this.items[c].label != undefined)
              toastr.info(
                'El campo "' + this.items[c].label + '" no debe aparecer vacio'
              );
          }
        }
      }

      return result;
    },
    loaderSpinClasss() {
      this.isLoading = !this.isLoading;
      const submitButton = this.$refs.buttonLoader;
      submitButton.classList.add(
        "disabled",
        "spinner",
        "spinner-success",
        "spinner-left"
      );
    },
    removeLoaderSpinClass() {
      this.isLoading = !this.isLoading;
      const submitButton = this.$refs.buttonLoader;
      submitButton.classList.remove(
        "disabled",
        "spinner",
        "spinner-success",
        "spinner-left"
      );
    },
    improcedente() {
      this.showCertificate = false;
      this.$emit("hideModalCertificate", true);
    },
    loadInfo() {
      if (!this.num_ot) {
        axios.get(`ots/${this.id_ot}`).then((res) => {
          this.num_ot = res.data[0].num_ot;
        });
      }
      axios
        .get("/bitacora-ot/" + this.num_ot)
        .then((res) => {
          const data = res.data;
          this.avs = data.avisos.map((el) => {
            return el.avisos_o_t_s.num_aviso;
          });

          let mov = data.movil;
          
          this.movil_2 = data.movil;
          this.rechazos = data.rechazo;

          if (mov && mov.trafo_parcial) {
            this.data.trafo.id = mov.trafo_parcial;
            this.data.trafo.inicio = mov
              ? mov.aperturaTrafo.replace(" ", "T")
              : "";
            this.data.trafo.fin = mov ? mov.cierreTrafo.replace(" ", "T") : "";

            this.trafo.inicio = mov ? mov.aperturaTrafo.replace(" ", "T") : "";
            this.trafo.fin = mov ? mov.cierreTrafo.replace(" ", "T") : "";
          }
          this.pendiente = data.historico;
          this.materiales = data.materiales;
          this.retirados = data.dato_materiales_retirados;
          this.instalados = data.dato_materiales;

          this.data.fecha_asigna = data.ot.fecha_asigna
            ? data.ot.fecha_asigna.replace(/-/g, "/")
            : "";
          this.data.fecha_causa = mov ? mov.fecha_causa.replace(/-/g, "/") : "";
          this.data.fecha_resolucion = mov
            ? mov.fecha_finaliza.replace(/-/g, "/")
            : "";
          this.data.fecha_recibe = mov
            ? mov.fecha_recepcion.replace(/-/g, "/")
            : "";
          this.data.observacion_bol = mov ? mov.observacion : "";

          this.acciones = data.acciones;

          this.data.causa = mov ? mov.causa_id : 0;
          this.data.fecha_localiza_causa = mov
            ? mov.fecha_causa.replace(/-/g, "/")
            : "";
          this.data.fecha_ot_fallida = mov
            ? mov.resultado != 1
              ? moment(mov.fecha_arribo_sitio).format("YYYY-MM-DD HH:mm:ss")
              : "null"
            : "null";

          // console.log(moment(mov.fecha_arribo_sitio).format("YYYY-MM-DD HH:mm:ss"));
          this.data.danio = mov ? (mov.pregunta_id == 1 ? 1 : 0) : 0;
          this.data.fecha_arribo = mov
            ? mov.fecha_arribo_sitio.replace(/-/g, "/")
            : "";

          this.informationOT = [res.data.ot];
          if (res.data.avisos[0]) {
            this.informationWarningOT = res.data.avisos[0].avisos_o_t_s;
            let keys = [
                "id_aviso",
                "tipo_trabajo_id",
                "created_at",
                "update_at",
              ],
              warning = [];
            for (const key in this.informationWarningOT) {
              if (!keys.includes(key)) {
                warning.push({
                  titulo: key.replace("_", " ").toUpperCase(),
                  detalle: this.informationWarningOT[key],
                });
              }
            }
            this.informationWarningOT = warning;
          }
          this.binnacle = [];
          this.binnacle = res.data.bitacora;
          this.movil = res.data.movil;
          this.data.observacion_cor = `AZT=${
            res.data.ot.user ? res.data.ot.user.nombre : "123456789"
          }[${this.brigada}] ${moment().format("LLL")} - OT ${this.num_ot}`;
          let info = "";
          if (this.movil) {
            this.movil.accion.map((el) => {
              info += el.descripcion + "  \n";
            });
          }
          this.data.observacion_cor = this.data.observacion_cor + "\n" + info;
          this.data.observacion_bol =
            (mov ? mov.observacion : " ") + "\n\n" + info;
          if (mov) {
            mov.observacion = (mov ? mov.observacion : " ") + "\n\n" + info;
          }

          let ref = [],
            cause = [
              "principal_id",
              "causa_id",
              "tipo_os",
              "resultado",
              "contrata",
              "tipo_cierre",
              "obj",
              "trafo_parcial",
              "created_at",
              "updated_at",
              "deleted_at",
            ];
          if (this.movil) {
            let el = this.movil;
            for (const key in el) {
              if (!cause.includes(key) && !_.isObject(el[key])) {
                ref.push({
                  titulo: key ? key.replace("_", " ").toUpperCase() : "",
                  detalle: el[key],
                });
              }
              if (key == "pregunta_id") {
                ref.push({
                  titulo: "¿HUBO DAÑO?",
                  detalle: el[key] == 1 ? "NO" : "SI",
                });
              }
              if (key === "trafo_parcial") {
                ref.push({
                  titulo: "HAY " + key.replace("_", " ").toUpperCase(),
                  detalle: el[key] === 1 || el[key] === null ? "NO" : "SI",
                });
              }
            }
            this.movil.causa = ref;
          }
          // console.log(data);
          // console.log(this.informationOT);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
