<template>
  <div id="kt_header" class="header">
    <div
      class="container-fluid topbar-container-fluid-vue d-flex align-items-stretch justify-content-between flex-wrap flex-sm-nowrap"
    >
      <div class="header-menu-wrapper header-menu-wrapper-right">
        <div
          id="kt_header_menu"
          class="header-menu header-menu-mobile header-menu-layout-default"
        >
          <ul class="menu-nav mt-1">
            <li v-for="(item, index) in itemsMenu" :key="index">
              <router-link
                v-if="item.redirection && can(item.permission)"
                :to="{ name: item.name }"
                class="btn btn-sm mt-1 text-sm vue-item-menu"
              >
                <i :class="item.icon"></i> <br />
                {{ item.text }}
              </router-link>
              <button
                v-if="index == 0 && can('change-zone')"
                @click="openDropdown()"
                class="btn btn-sm mt-1 text-sm"
              >
                <i :class="item.icon"></i> <br />
                {{ item.text }}
              </button>
            </li>
          </ul>
          <b-modal
            id="modal-center"
            ref="modal"
            centered
            title="Zona de trabajo"
            v-model="open"
            no-close-on-backdrop
          >
            <div class="row justify-content-center">
              <div class="col-md-12">
                <label>Seleccione zona para trabajar</label>
                <select v-model="cor" class="form-control">
                  <option :value="null">Seleccione</option>
                  <option
                    v-for="(item, index) in items"
                    :key="index"
                    :value="`'${item.nombre}'`"
                  >
                    {{ item.nombre }}
                  </option>
                </select>
              </div>
            </div>
            <template #modal-footer>
              <div class="w-100">
                <b-button
                  @click="confirmWorkZone()"
                  variant="outline-primary"
                  size="sm"
                  class="float-right mr-2"
                >
                  <i class="la la-map-marked-alt"></i> Confirmar
                </b-button>
              </div>
            </template>
          </b-modal>
          <selectSectors
            ref="sectors"
            @loadTree="confirmWorkZone()"
            v-if="can('change-zone')"
          />
        </div>
      </div>
      <TopBar  @ubicateCertified="ubicateCertified($event)"/>
    </div>
  </div>
</template>
<style>
:root {
  --border: #f2f2f2;
}
.modal-backdrop {
  opacity: 0.7;
}
.vue-item-menu {
  border-right: 1px solid var(--border) !important;
  border-left: 1px solid var(--border) !important;
}
</style>
<script>
import TopBar from "./topBar.vue";
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import toastr from "toastr";
import selectSectors from "@/components/helpers/modals/selectSectors.vue";
import _ from "lodash";
import can from "@/permission";
export default {
  components: {
    TopBar,
    selectSectors,
  },
  data() {
    return {
      cor: null,
      items: [],
      open: false,
      itemsMenu: [
        {
          id: 1,
          text: "Camb. zona",
          redirection: false,
          icon: "flaticon-placeholder-3 text-primary opacity-70",
        },
        {
          id: 2,
          text: "Home",
          name: "home",
          permission: "/home",
          redirection: true,
          icon: "fa fa-home text-info",
        },
        {
          id: 3,
          name: "ProcessesWorks",
          text: "Mapa",
          permission: "/works",
          icon: "flaticon-map-location text-primary",
          redirection: true,
        },
        {
          id: 4,
          name: "adminMap",
          text: "Mapa",
          permission: "/admin-map",
          icon: "flaticon-map-location text-primary",
          redirection: true,
        },

        {
          id: 5,
          name: "ProcessesReports",
          permission: "/reports",
          icon: "fa fa-file-alt text-primary",
          text: "Reportes",
          redirection: true,
        },
        {
          id: 7,
          name: "managementOT",
          text: "Admin. OT",
          permission: "/processes/management",
          icon: "fa fa-object-group text-primary",
          redirection: true,
        },
        {
          id: 8,
          name: "users",
          text: "Usuarios",
          permission: "/users",
          redirection: true,
          icon: "flaticon-user text-success",
        },
        {
          id: 9,
          name: "permissions",
          text: "Permisos",
          permission: "/permissions",
          redirection: true,
          icon: "flaticon-safe-shield-protection text-primary",
        },
        {
          id: 10,
          name: "roles",
          text: "Roles",
          permission: "/roles",
          redirection: true,
          icon: "flaticon-users-1 text-primary",
        },
        {
          id: 11,
          name: "turns",
          text: "Turnos",
          permission: "/turns",
          redirection: true,
          icon: "flaticon-calendar-with-a-clock-time-tools text-dark",
        },
        {
          id: 12,
          name: "vehicles",
          text: "Vehiculos",
          permission: "/vehicles",
          redirection: true,
          icon: "flaticon2-lorry text-dark opacity-80",
        },
        {
          id: 13,
          name: "days",
          text: "Jornadas",
          permission: "/days",
          redirection: true,
          icon: "flaticon-event-calendar-symbol text-info",
        },
        {
          id: 14,
          name: "settlement",
          text: "Liquidación",
          permission: "/settlement",
          redirection: true,
          icon: "la la-money-bill-wave-alt text-success",
        },
        {
          id: 15,
          name: "vehicleType",
          text: "Tipo Vehic.",
          permission: "/vehicle-type",
          redirection: true,
          icon: "flaticon2-lorry text-dark opacity-80",
        },
        {
          id: 16,
          name: "marks",
          text: "Marcas",
          permission: "/marks",
          redirection: true,
          icon: "flaticon-clipboard text-warning opacity-80",
        },
        {
          id: 17,
          name: "actions",
          text: "Acciones.",
          permission: "/actions",
          redirection: true,
          icon: "flaticon-web text-danger opacity-80",
        },
        {
          id: 17,
          name: "consulta-ws",
          text: "WS",
          permission: "/consulta-ws",
          redirection: true,
          icon: "flaticon-technology-2 text-info",
        },
        {
          id: 17,
          name: "brigadeTour",
          text: "Recorridos",
          permission: "/brigadeTour",
          redirection: true,
          icon: "flaticon-location text-primary",
        },
        {
          id: 17,
          name: "reportAdmin",
          text: "Informes",
          permission: "/reports-admin",
          redirection: true,
          icon: "flaticon-folder-1 text-danger opacity-80",
        },
        {
          id: 18,
          name: "OtherReport",
          text: "Detalle reporte",
          permission: "/other-report",
          redirection: true,
          icon: "flaticon-folder-1 text-danger opacity-80",
        }
      ],
    };
  },
  computed: {
    ...mapGetters({
      getCor: "auth/cor",
    }),
  },
  methods: {
    can: can,
    ubicateCertified(event) {
      console.log(event);
      const works = this.$parent.$children[3];
      const warning = works.certifieds.find(f => f.ordenNumber == event.ordenNumber)
      const indexWarning = works.certifieds.find(f => f.ordenNumber == event.ordenNumber)
      if(indexWarning != -1) {
        works.toggleInfoWindow(warning, indexWarning)
      }

    },
    openDropdown() {
      this.loadCor();
      // this.open = !this.open
      let modal = this.$refs["sectors"];
      if (sessionStorage.getItem("cor") != null) {
        let c = sessionStorage.cor.replaceAll("'", "").split(",");
        modal.loadSector();
        modal.cors = _.uniq(c);
        modal.defaultInfo();
        modal.isShow = true;
      }
    },
    ...mapActions({
      changeCor: "auth/zoneWork",
    }),
    loadCor() {
      axios
        .get("/cors")
        .then((res) => {
          this.items = res.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    confirmWorkZone() {
      if (sessionStorage.cor == "") {
        toastr.info("Seleccione una zona de trabajo para continuar");
      } else {
        this.changeCor(sessionStorage.cor);
        this.$emit("loadCor", this.cor);
        if (this.$route.path == "/processes/management") {
          this.$forceUpdate();
        }
      }
    },
  },
};
</script>
