import moment from "moment"
const computeDistanceBetweenG = (pos1, pos2) => {
    return window.google.maps.geometry.spherical.computeDistanceBetween(pos1, pos2)
};
// import Geocoder from "@/assets/utils/geocoderAPI.js";

const firstInfoTech = (worker) => {
    // const address = Geocoder({lat: parseFloat(worker.ultima_posicion.latitud),lng: parseFloat(worker.ultima_posicion.longitud)});
    // console.log(address);
    var mouvement = false;
    const positions = worker.gps_pos;
    if (positions && positions.length == 2) {
        const [init, last] = positions;
        var pos = new window.google.maps.LatLng(parseFloat(init.latitud), parseFloat(init.longitud))
        var pos2 = new window.google.maps.LatLng(parseFloat(last.latitud), parseFloat(last.longitud))
        try {
            if ((init.latitud != last.latitud) && (init.longitud != last.longitud)) {
                if (computeDistanceBetweenG(pos, pos2) >= 10) mouvement = true;
            }
        } catch (error) {
            console.log(error);
        }
    }
    return ` <strong>
                <span style="color: #F64E60; font-weight: bold;">Brigada:</span> ${worker.nombre} <br> 
                <span style="color: #F64E60; font-weight: bold;">Movil:</span> <a href="tel:${worker.cliente.movil}"> ${worker.cliente.movil} </a> <br> 
                <span style="color: #F64E60; font-weight: bold;">Identificación:</span> ${worker.cliente.nif} <br> 
                <span style="color: #F64E60; font-weight: bold;">Vehiculo:</span> ${worker.cliente ? worker.cliente.vehiculo.toUpperCase() : ''} <br> 
                <span style="color: #F64E60; font-weight: bold;">Tipo de vehículo:</span> ${worker.vehiculo_info.tipo_vehiculo} <br> 
                <span style="color: #F64E60; font-weight: bold;">${worker.ultima_posicion ? 'Último GPS' : ''}:</span> ${worker.ultima_posicion ? moment(`${worker.ultima_posicion.fecha} ${worker.ultima_posicion.hora}`).format('llll') : ''} <br> 
                <span style="color: #F64E60; font-weight: bold;">${worker.usuario_sesion ? ('Fecha de ' + (worker.usuario_sesion.estado == 1 ? ' ingreso' : ' cierre ')) : ''}:
                </span> 
                ${worker.usuario_sesion ? moment(`${worker.usuario_sesion.fecha}`).format('llll') : ''} <br> 
                <i class="flaticon-truck text-${mouvement ? 'success' : 'danger'}"> </i>
                <span class="text-${mouvement ? 'success' : 'danger'}"> ${mouvement ? 'En movimiento' : 'Estacionado'}</span>  
            </strong>`
}
export default firstInfoTech


