<template>
  <button
    style="margin: 10px 8px 0px 0px"
    class="btn btn-sm btn-link mt-3 without-decoration"
    @click="ubicateTree(infoWinContent)"
  >
    <icon-eye /> Ubicar
  </button>
</template>
<style>
.without-decoration:hover {
  text-decoration: none;
}
</style>
<script>
import iconEye from "./icons/Eye.vue";
import * as $ from "jquery";

export default {
  components: { iconEye },
  props: {
    infoWinContent: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    ubicateTree(info) {
      const content = $("#main-tree-information");
      const [app] = this.$root.$children;
      const multipane = app.$children[1];
      const [menu] = multipane.$children;
      var data = menu.data;
      const selectOrderManaged = () => {
        data[8].children.map((sector) => {
          sector.children.map((subestacion) => {
            subestacion.children.map((circuito) => {
              circuito.children.map((order) => {
                order.selected = false;
                if (order.id_ot == info.id_ot) {
                  data[8].opened = true;
                  sector.opened = true;
                  subestacion.opened = true;
                  circuito.opened = true;
                  order.opened = true;
                  order.selected = true;
                  const scroll = content.scrollTop();
                  const scrollClass =
                    $(`.OT_${info.num_ot}`).offset().top + 100;
                  console.log(scroll, scrollClass);
                  // const height = content.height();

                  // console.log(content.offset());
                  // usamos el alto del contenedor para poder centrar el aviso en el árbol
                  content.animate(
                    {
                      scrollTop: scroll > scrollClass ? scroll : scrollClass,
                    },
                    2000
                  );
                }
              });
            });
          });
        });
      };
      const selectedPending = () => {
        data[7].children.map((sector) => {
          sector.children.map((subestacion) => {
            subestacion.children.map((circuito) => {
              circuito.children.map((order) => {
                order.selected = false;
                if (order.id_ot == info.id_ot) {
                  data[7].opened = true;
                  sector.opened = true;
                  subestacion.opened = true;
                  circuito.opened = true;
                  order.opened = true;
                  order.selected = true;
                  const scroll = content.scrollTop();
                  const scrollClass =
                    $(`.OT_${info.num_ot}`).offset().top + 100;
                  console.log(scroll, scrollClass);
                  content.animate(
                    {
                      scrollTop: scroll < scrollClass ? scroll : scrollClass,
                    },
                    2000
                  );
                }
              });
            });
          });
        });
      };
      switch (info.estado_ot) {
        case 1:
        case 7:
          // si la orden está en estado pendiente la buscamos en OT gestionadas
          // de esta forma
          // Estado 1 = pendiente
          // Estado 8 =  Rechazada
          selectedPending();
          break;
        // Si la orden se encuentra en algunos de los siguientes estados
        // 2 Asignada
        // 3 Descargada
        // 4 En Ejecución
        // 5 Ejecutada
        // Lo buscamos en el contenedor 8 - brigadas
        // debido a que en alguno de estos estados lo tiene asignado una brigada
        case 2:
        case 3:
        case 4:
        case 5:
          selectOrderManaged();
          break;

        default:
          break;
      }
      console.log(menu.data, "data");
      console.log(info);
    },
  },
};
</script>
