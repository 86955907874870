<template>
  <div id="kt_quick_user" class="offcanvas offcanvas-right p-10">
    <div
      class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
    >
      <h3 class="font-weight-bold m-0">
        Perfil
        <small class="text-muted font-size-sm ml-2"></small>
      </h3>
      <a
        class="btn btn-xs btn-icon btn-light btn-hover-primary"
        id="kt_quick_user_close"
        @click="quickUserPanelToggle()"
      >
        <i class="ki ki-close icon-xs text-muted"></i>
      </a>
    </div>

    <div class="offcanvas-content pr-5 mr-n5">
      <div class="d-flex align-items-center mt-5">
        <div class="symbol symbol-85 mr-5">
          <div
            class="symbol-label bg-white"
            style="background-image: url('assets/media/users/300_21.png')"
          ></div>
          <i class="symbol-badge bg-success"></i>
        </div>
        <div class="d-flex flex-column">
          <a
            href="#"
            class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
          >
            {{ user.name }}
          </a>
          <div class="navi mt-2">
            <a href="#" class="navi-item">
              <span class="navi-link p-0 pb-2">
                <span class="navi-icon mr-1">
                  <img
                    src="assets/media/svg/icons/Communication/Mail.svg"
                    class="opacity-40"
                  />
                </span>
                <span class="navi-text text-muted text-hover-primary">
                  {{ user.email }}
                </span>
              </span>
            </a>
          </div>
          <div class="navi mt-2">
            <a href="#" @click.prevent="signOut()" class="navi-item">
              <span class="navi-link p-0 pb-2">
                <span class="navi-icon mr-1">
                  <img
                    src="assets/media/svg/icons/Electric/Shutdown.svg"
                    class="opacity-40"
                  />
                </span>
                <span class="navi-text text-muted text-hover-primary">
                  Cerrar sesión
                </span>
              </span>
            </a>
          </div>
        </div>
      </div>
      <div class="separator separator-dashed mt-2 mb-2"></div>
      <div class="navi navi-spacer-x-0 p-0">
        <div class="navi-item">
          <div class="navi-link">
            <div class="text-muted">
              <img src="/assets/media/svg/icons/Home/Clock.svg" class="mb-2" />
              <span class="mt-3 time">
                {{ time }}
              </span>
            </div>
          </div>
          <div class="navi-link" v-if="false">
            <div class="text-muted">
              <button @click="altTheme()" class="btn btn-sm btn-link">
                <i class="fa fa-moon"></i> Mood
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-dashed my-1 mb-3"></div>
      <Calendar :user="user" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Swal from "sweetalert2";

import Calendar from "../helpers/calendar/Calendar.vue";
// import * as $ from "jquery";
export default {
  name: "quick-user",
  components: {
    Calendar,
  },
  data() {
    return {
      interval: null,
      time: null,
      // theme false is Dark
      theme: true,
    };
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  created() {
    this.interval = setInterval(() => {
      this.time = Intl.DateTimeFormat(navigator.language, {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      }).format();
    }, 1000);
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
  },
  methods: {
    ...mapActions({
      signOutAction: "auth/signOut",
    }),
    altTheme() {
      this.theme = !this.theme;
    },
    signOut() {
      Swal.fire({
        title: "¿Desea salir del sistema?",
        showDenyButton: true,
        showConfirmButton: true,
        icon: "question",
        confirmButtonText: "Si, salir",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          this.signOutAction().then(() => {
            this.$router.replace({
              name: "login",
            });
          });
        } else if (result.isDenied) {
          Swal.fire("Proceso cancelado");
        }
      });
    },
    quickUserPanelToggle() {
      const body = document.body;
      if (body.classList.contains("offcanvas-on")) {
        body.classList.remove("offcanvas-on");
        document
          .getElementById("kt_quick_user")
          .classList.remove("offcanvas-on");
      } else {
        body.classList.add("offcanvas-on");
        document.getElementById("kt_quick_user").classList.add("offcanvas-on");
      }
    },
  },
  watch: {
    // theme() {
    //   const dark_8 = "assets/css/themes/layout/header/base/dark.css";
    //   if (!this.theme) {
    //     $("head link").each((index, item) => {
    //       switch (index) {
    //         case 8:
    //           //   console.log(item);
    //           item;
    //           var link = document.createElement("link");
    //           link.href = dark_8;
    //           break;
    //       }
    //     });
    //   }
    // },
  },
};
</script>
<style>
#kt_quick_user_close:hover {
  cursor: pointer;
}
.time {
  font-size: 1.1rem;
}
</style>
